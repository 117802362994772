import {apiUrl, round} from "../config/config";
import {authHeader} from '../helpers';

export const userService = {
    login,
    logout,
    forgotPass,
    resetPassword,
    rounds,
    getBudges,
    addNotes,
    getServerDate,
    getCurrentRound,
    getCurrentRoundForHome,
    getRoundVoting,
    getRevotedUsers,
    submitVoting,
    submitRevoting,
    getUsersForSpecialPrice,
    finishPerformance,
    getRoundResult
    // getAll,
    // getById
};

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email, password})
    };

    return fetch(`${apiUrl}/jury/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', JSON.stringify(user.data.token));
            localStorage.setItem('user', JSON.stringify(user.data));
            window.location.reload();
            return user.data;
        });
}


function forgotPass(email, url) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify({email, url})
    };
    return fetch(`${apiUrl}/jury/forgot-password`, requestOptions)
        .then(handleResponse)
        .then(res => {
            return res.data;
        });
}


function addNotes(note, jury_id, round_id, user_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({note, jury_id, round_id, user_id})
    };
    return fetch(`${apiUrl}/notes`, requestOptions)
        .then(handleResponse)
        .then(res => {
            localStorage.setItem('token', JSON.stringify(res.token));
            return res.data;
        });
}


function finishPerformance(jury_id, performance_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({jury_id, performance_id})
    };
    return fetch(`${apiUrl}/jury_performance_finish`, requestOptions)
        .then(handleResponse)
        .then(res => {
            localStorage.setItem('token', JSON.stringify(res.token));
            return res.data;
        });
}


function submitVoting(result) {
    // console.log(JSON.parse(localStorage.getItem('token')));
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(result)
    };
    return fetch(`${apiUrl}/all_votes`, requestOptions)
        .then(handleResponse)
        .then(res => {
            localStorage.setItem('token', JSON.stringify(res.token));
            window.location.reload();
            return res.data;
        });
}



function submitRevoting(result) {
    // console.log(JSON.parse(localStorage.getItem('token')));
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(result)
    };
    return fetch(`${apiUrl}/all_votes_revoting`, requestOptions)
        .then(handleResponse)
        .then(res => {
            localStorage.setItem('token', JSON.stringify(res.token));
            return res.data;
        });
}


function resetPassword(token, password) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify({token, password})
    };
    return fetch(`${apiUrl}/jury/reset-password`, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('token', JSON.stringify(user.data.token));
            localStorage.setItem('user', JSON.stringify(user.data));
            window.location.reload();
            return user.data;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.reload();
    return false;
}

//
// function getAll() {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };
//
//     return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
// }
//


function getServerDate(id) {
    // console.log(JSON.parse(localStorage.getItem('token')));
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/server_date`, requestOptions).then(handleResponse).then(res => {
        return res.data
    });
}

// function getSchedules(id) {
//     // console.log(JSON.parse(localStorage.getItem('token')));
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };
//
//     return fetch(`${apiUrl}/schedules?round_id=1`, requestOptions).then(handleResponse).then(res => {
//         return res.data
//     });
// }

function getBudges(id) {
    // console.log(JSON.parse(localStorage.getItem('token')));
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/badges`, requestOptions).then(handleResponse).then(res => {
        return res.data
    });
}

function rounds(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/rounds?competition_id=${round}`, requestOptions).then(handleResponse).then(res => {
        return res.data
    });
}


function getCurrentRound() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/current_round`, requestOptions).then(handleResponse).then(res => {
        // localStorage.setItem('token', JSON.stringify(res.token));
        return res.data
    });
}


function getCurrentRoundForHome() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${apiUrl}/current_round`, requestOptions).then(handleResponse).then(res => {
        // localStorage.setItem('token', JSON.stringify(res.token));
        return res.data
    });
}

function getRoundVoting() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${apiUrl}/current_round/users`, requestOptions).then(handleResponse).then(res => {
        localStorage.setItem('token', JSON.stringify(res.token));
        return res.data
    });
}





function getRevotedUsers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${apiUrl}/current_round/users_revoting`, requestOptions).then(handleResponse).then(res => {
        localStorage.setItem('token', JSON.stringify(res.token));
        return res.data
    });
}



function getRoundResult(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${apiUrl}/rounds/${id}/users_list`, requestOptions).then(handleResponse).then(res => {
        // localStorage.setItem('token', JSON.stringify(res.token));
        return res.data
    });
}


function getUsersForSpecialPrice() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${apiUrl}/competitions/1/users`, requestOptions).then(handleResponse).then(res => {
        localStorage.setItem('token', JSON.stringify(res.token));
        return res.data
    });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // location.reload(true);
            }
            if (response.status === 422) {
                // auto logout if 401 response returned from api
                const error = (data && data.errors.email[0]) || response.statusText;
                return Promise.reject(error);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        let token = response.headers.get('authorization') && response.headers.get('authorization');
        let date = response.headers.get('server_date');
        return {
            data: data,
            token: token,
            date: date
        };
    });
}
