export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',


    FORGOTPASS_REQUEST: 'USERS_FORGOTPASS_REQUEST',
    FORGOTPASS_SUCCESS: 'USERS_FORGOTPASS_SUCCESS',
    FORGOTPASS_FAILURE: 'USERS_FORGOTPASS_FAILURE',


    RESETPASSWORD_REQUEST: 'USERS_RESETPASSWORD_REQUEST',
    RESETPASSWORD_SUCCESS: 'USERS_RESETPASSWORD_SUCCESS',
    RESETPASSWORD_FAILURE: 'USERS_RESETPASSWORD_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',



    GET_PERFORMANCES_REQUEST: 'GET_PERFORMANCES_REQUEST',
    GET_PERFORMANCES_SUCCESS: 'GET_PERFORMANCES_SUCCESS',
    GET_PERFORMANCES_FAILURE: 'GET_PERFORMANCES_FAILURE',



    GET_ROUNDS_REQUEST: 'GET_ROUNDS_REQUEST',
    GET_ROUNDS_SUCCESS: 'GET_ROUNDS_SUCCESS',
    GET_ROUNDS_FAILURE: 'GET_ROUNDS_FAILURE',



    GET_SCHEDULES_REQUEST: 'GET_SCHEDULES_REQUEST',
    GET_SCHEDULES_SUCCESS: 'GET_SCHEDULES_SUCCESS',
    GET_SCHEDULES_FAILURE: 'GET_SCHEDULES_FAILURE',



    GET_BUDGES_REQUEST: 'GET_BUDGES_REQUEST',
    GET_BUDGES_SUCCESS: 'GET_BUDGES_SUCCESS',
    GET_BUDGES_FAILURE: 'GET_BUDGES_FAILURE',



    ADD_NOTES_REQUEST: 'ADD_NOTES_REQUEST',
    ADD_NOTES_SUCCESS: 'ADD_NOTES_SUCCESS',
    ADD_NOTES_FAILURE: 'ADD_NOTES_FAILURE',



    GET_SERVERTIME_REQUEST: 'GET_SERVERTIME_REQUEST',
    GET_SERVERTIME_SUCCESS: 'GET_SERVERTIME_SUCCESS',
    GET_SERVERTIME_FAILURE: 'GET_SERVERTIME_FAILURE',



    GET_CURRENTROUND_REQUEST: 'GET_CURRENTROUND_REQUEST',
    GET_CURRENTROUND_SUCCESS: 'GET_CURRENTROUND_SUCCESS',
    GET_CURRENTROUND_FAILURE: 'GET_CURRENTROUND_FAILURE',



    GET_CURRENTROUNDFORMHOME_REQUEST: 'GET_CURRENTROUNDFORMHOME_REQUEST',
    GET_CURRENTROUNDFORMHOME_SUCCESS: 'GET_CURRENTROUNDFORMHOME_SUCCESS',
    GET_CURRENTROUNDFORMHOME_FAILURE: 'GET_CURRENTROUNDFORMHOME_FAILURE',

    GET_CURRENTROUNDVOTING_REQUEST: 'GET_CURRENTROUNDVOTING_REQUEST',
    GET_CURRENTROUNDVOTING_SUCCESS: 'GET_CURRENTROUNDVOTING_SUCCESS',
    GET_CURRENTROUNDVOTING_FAILURE: 'GET_CURRENTROUNDVOTING_FAILURE',




    GET_CURRENTROUNDREVOTE_REQUEST: 'GET_CURRENTROUNDREVOTE_REQUEST',
    GET_CURRENTROUNDREVOTE_SUCCESS: 'GET_CURRENTROUNDREVOTE_SUCCESS',
    GET_CURRENTROUNDREVOTE_FAILURE: 'GET_CURRENTROUNDREVOTE_FAILURE',



    GET_CURRENTROUNDREVOTESUBMIT_REQUEST: 'GET_CURRENTROUNDREVOTESUBMIT_REQUEST',
    GET_CURRENTROUNDREVOTESUBMIT_SUCCESS: 'GET_CURRENTROUNDREVOTESUBMIT_SUCCESS',
    GET_CURRENTROUNDREVOTESUBMIT_FAILURE: 'GET_CURRENTROUNDREVOTESUBMIT_FAILURE',

    GET_GETUSERSFORSPECIALPRICE_REQUEST: 'GET_GETUSERSFORSPECIALPRICE_REQUEST',
    GET_GETUSERSFORSPECIALPRICE_SUCCESS: 'GET_GETUSERSFORSPECIALPRICE_SUCCESS',
    GET_GETUSERSFORSPECIALPRICE_FAILURE: 'GET_GETUSERSFORSPECIALPRICE_FAILURE',


    ADD_VOTING_REQUEST: 'ADD_VOTING_REQUEST',
    ADD_VOTING_SUCCESS: 'ADD_VOTING_SUCCESS',
    ADD_VOTING_FAILURE: 'ADD_VOTING_FAILURE',


    ADD_REVOTING_REQUEST: 'ADD_REVOTING_REQUEST',
    ADD_REVOTING_SUCCESS: 'ADD_REVOTING_SUCCESS',
    ADD_REVOTING_FAILURE: 'ADD_REVOTING_FAILURE',


    ADD_FINISHPERF_REQUEST: 'ADD_FINISHPERF_REQUEST',
    ADD_FINISHPERF_SUCCESS: 'ADD_FINISHPERF_SUCCESS',
    ADD_FINISHPERF_FAILURE: 'ADD_FINISHPERF_FAILURE',




    GET_RESULT_REQUEST: 'GET_RESULT_REQUEST',
    GET_RESULT_SUCCESS: 'GET_RESULT_SUCCESS',
    GET_RESULT_FAILURE: 'GET_RESULT_FAILURE',
};
