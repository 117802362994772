import React from 'react';
import {AvForm, AvGroup, AvInput, AvFeedback} from 'availity-reactstrap-validation';
import {Label} from 'reactstrap';
import {connect} from 'react-redux';
import {userActions} from '../actions';
import {withRouter} from 'react-router-dom';
import {history} from '../helpers';
import Alert from "../components/Alert";


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            url: window.location.origin + '/reset-password',
            submitted: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }


    componentDidMount() {
        if (localStorage.getItem('user')) {
            history.push('/')
        }

    }

    handleValidSubmit(event, values) {
        this.setState({
            email: values.email,
            submitted: true
        });
        const {email, url} = this.state;
        if (email) {
            this.props.forgotPass(email, url);
        }
        setTimeout(() => {
            this.setState({
                submitted: false
            })
        }, 3000)
    }

    onClick = () => {
        this.setState({
            submitted: false
        })
    }

    render() {
        const {mailSendingForPassword, fail, validMail} = this.props;
        const {email, submitted} = this.state;
        return (
            <div className="Login">
                <Alert show={fail && submitted} msg={fail} onClick={e => this.onClick(e)}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="Login-bg" style={{backgroundImage: 'url(/img/login-bg.png)'}}/>
                        <div className="container content">
                            <div className="row">
                                <div className="col-md-4 my-col-md-4">
                                    <AvForm autoComplete="off" onValidSubmit={this.handleValidSubmit}>
                                        {validMail ? (
                                            <div className="row">
                                                <p className="note">
                                                    An email has been sent to your email address, with the link to reset
                                                    password.
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className="my-title orange">
                                                        FORGOT PASSWORD
                                                    </h2>
                                                    <p className="note mt-3">
                                                        Please enter your email in the field below to reset
                                                        password.
                                                    </p>
                                                </div>
                                                <div className="col-md-12">
                                                    <AvGroup>
                                                        <AvInput id="mail" name="email" type="email" required
                                                                 tabIndex={1} autoComplete="off" value={email}
                                                        />
                                                        <Label className="form-control-label" for="mail">
                                                            Email *
                                                        </Label>
                                                        <AvFeedback>This field is invalid</AvFeedback>
                                                    </AvGroup>
                                                </div>
                                                <div className="col-md-12">
                                                    <button className="my-btn" type="submit">
                                                        {mailSendingForPassword ?
                                                            (<img
                                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>) : (
                                                                <span>
                                                                     SEND
                                                                 </span>
                                                            )
                                                        }
                                                    </button>

                                                </div>
                                            </div>
                                        )}
                                    </AvForm>
                                </div>
                                <div className="col-md-1"/>
                                <div className="col-md-7 my-col-md-7">
                                    <div className="info">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const {mailSendingForPassword, mailSending, validMail, fail} = state.authentication;
    return {mailSendingForPassword, mailSending, validMail, fail};
}

const actionCreators = {
    forgotPass: userActions.forgotPass
};
export default withRouter(connect(mapState, actionCreators)(ForgotPassword))
