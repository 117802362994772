import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";

import App from "./App";
import { store } from './helpers';
// import * as serviceWorker  from './serviceWorker';
// import { configureFakeBackend } from './helpers';
// configureFakeBackend();

const jsx = (
    <Provider store={store}>
        <App/>
    </Provider>
);

const app = document.getElementById("root");
ReactDOM.hydrate(jsx, app);
// serviceWorker.register();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
