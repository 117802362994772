import React from 'react';
import {Modal} from "react-bootstrap";

class InstructionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleModal: false,
            day: 1,
            txt: 1,
            myRound: 3,
            closeAutoplayModal: true
        }
    }

    componentDidMount() {


    }


    closeAutoplayModal = () => {
        const {round} = this.props
        const {day, myRound} = this.state
        let delayForShow = 9000;
        let delayForhide = 500;
        let vid = document.getElementById('liveVideoPlayer') !== null && document.getElementById('liveVideoPlayer');
        vid.autoplay = true;
        vid.play();
        this.setState({
            closeAutoplayModal: false,
        })
        // this.setState({
        //     closeAutoplayModal: false,
        //     toggleModal: true
        // }, () => {
        //     setTimeout(() => {
        //         this.setState({
        //             toggleModal: false,
        //         }, () => {
        //             setTimeout(() => {
        //                 this.setState({
        //                     toggleModal: true,
        //                     txt: 2
        //                 }, () => {
        //                     setTimeout(() => {
        //                         this.setState({
        //                             toggleModal: false
        //                         }, () => {
        //                             if ((round.id === 1 && round.day === 3) || (round.id === 2 && round.day === 2) || round.id === 3) {
        //                                 setTimeout(() => {
        //                                     this.setState({
        //                                         toggleModal: true,
        //                                         txt: 3
        //                                     }, () => {
        //                                         setTimeout(() => {
        //                                             this.setState({
        //                                                 toggleModal: false,
        //                                             })
        //                                         }, delayForShow)
        //                                     })
        //                                 }, delayForhide)
        //                             }
        //                         })
        //                     }, delayForShow)
        //                 })
        //             }, delayForhide)
        //         })
        //     }, delayForShow)
        // })
    }

    render() {
        const {round, onHideModals, date} = this.props;
        const {toggleModal, day, txt, myRound, closeAutoplayModal} = this.state
        const styleOPtions = {
            fontSize: '1.8vw',
            fontWeight: 400
        }
        return (
            <div className="instruction-modal">
                <Modal show={closeAutoplayModal} size="lg" aria-labelledby="contained-modal-title-vcenter"
                       className="instruction-modal" centered>
                    <Modal.Body>
                        <h3 className="text-center blue fw-bold"
                            style={styleOPtions}>
                            <b>
                                Dear Jury Member, please click OK view the performance
                            </b>
                        </h3>
                    </Modal.Body>
                    <div className="modal-btn">
                        <button onClick={e => this.closeAutoplayModal(e)}>
                            OK
                        </button>
                    </div>
                </Modal>
                {/*<Modal show={toggleModal} size="lg" aria-labelledby="contained-modal-title-vcenter"*/}
                       {/*className="instruction-modal" centered onExit={onHideModals}>*/}
                    {/*<Modal.Body>*/}
                        {/*{round.id === 1 && round.day === 1 && txt === 1 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Voting for the nominations should start from Day 1 of the 1st round. <br/> <br/>*/}
                                    {/*The results will be announced at the end of the 3rd round</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}
                        {/*{round.id === 1 && round.day === 1 && txt === 2 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please contact us immediately through the chat box on the right bottom corner*/}
                                    {/*of your screen in case you have a <br/> question or you encounter a technical*/}
                                    {/*problem.</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}

                        {/*{round.id === 1 && round.day === 2 && txt === 1 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please continue voting for the nominations</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}

                        {/*{round.id === 1 && round.day === 2 && txt === 2 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please contact us immediately through the chat box on the right bottom corner of your*/}
                                    {/*screen in case you have a question or you encounter a technical problem</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}


                        {/*{round.id === 1 && round.day === 3 && txt === 1 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Winners of the 1st round to be announced today</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}

                        {/*{round.id === 1 && round.day === 3 && txt === 2 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please continue voting for the nominations</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}

                        {/*{round.id === 1 && round.day === 3 && txt === 3 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please contact us immediately through the chat box on the right bottom corner of your*/}
                                    {/*screen in case you have a question or you encounter a technical problem</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}


                        {/*{round.id === 2 && round.day === 1 && txt === 1 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please continue voting for the nominations</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}
                        {/*{round.id === 2 && round.day === 1 && txt === 2 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please contact us immediately through the chat box on the right bottom corner of your*/}
                                    {/*screen in case you have a question or you encounter a technical problem</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}


                        {/*{round.id === 2 && round.day === 2 && txt === 1 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Winners of the 2nd round to be announced today</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}

                        {/*{round.id === 2 && round.day === 2 && txt === 2 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please continue voting for the nominations</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}

                        {/*{round.id === 2 && round.day === 2 && txt === 3 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please contact us immediately through the chat box on the right bottom corner of your*/}
                                    {/*screen in case you have a question or you encounter a technical problem</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}


                        {/*{round.id === 3 && round.day === 1 && txt === 1 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Winners of the 3rd round to be announced today</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}

                        {/*{round.id === 3 && round.day === 1 && txt === 2 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please continue voting for the nominations</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}

                        {/*{round.id === 3 && round.day === 1 && txt === 3 && (*/}
                            {/*<h3 className="text-center blue fw-bold"*/}
                                {/*style={styleOPtions}>*/}
                                {/*<b>Please contact us immediately through the chat box on the right bottom corner of your*/}
                                    {/*screen in case you have a question or you encounter a technical problem</b>*/}
                            {/*</h3>*/}
                        {/*)}*/}

                    {/*</Modal.Body>*/}
                {/*</Modal>*/}
            </div>
        )
    }
}

export default InstructionModal;
