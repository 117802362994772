import {userConstants} from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? {loggedIn: true, user} : {};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.FORGOTPASS_REQUEST:
            return {
                mailSendingForPassword: true,
                mailSending: true
            };
        case userConstants.FORGOTPASS_SUCCESS:
            return {
                validMail: true
            };
        case userConstants.FORGOTPASS_FAILURE:
            console.log(action)
            return {
                fail: action.error
            };
        case userConstants.RESETPASSWORD_REQUEST:
            return {
                mailSendingResetPassword: true
            };
        case userConstants.RESETPASSWORD_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                fail: action.error,
                loggedIn: false
            };
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}
