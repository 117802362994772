import React from 'react';
import {Modal} from "react-bootstrap";
class AlertForAutoPlay extends React.Component {
    handleClick = () =>{
        let video = document.getElementById('liveVideoPlayer') !== null && document.getElementById('liveVideoPlayer')
        video.autoPlay = true
    }
    render(){
        const {showAutoplayModal} = this.props
        return (
            <Modal show={showAutoplayModal}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Body>
                    AUTOPLAY TRUE
                </Modal.Body>
                <div className="modal-btn">
                    <button onClick={e=>this.handleClick(e)}>OK</button>
                </div>
            </Modal>
        )
    }
}
export default AlertForAutoPlay
