import {userConstants} from '../constants';
import {userService} from '../services';
import {alertActions} from './';
import {history} from '../helpers';

export const userActions = {
    login,
    forgotPass,
    resetPassword,
    logout,
    getAll,
    rounds,
    getRoundVoting,
    getBudges,
    addNotes,
    getServerDate,
    getCurrentRound,
    getRevotedUsers,
    getCurrentRoundForHome,
    submitVoting,
    submitRevoting,
    getUsersForSpecialPrice,
    finishPerformance,
    getRoundResult
};

function login(email, password) {
    return dispatch => {
        dispatch(request({email}));

        return userService.login(email, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/');
                    return dispatch(success(user));
                    // window.location.reload()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) {
        return {type: userConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: userConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    userService.logout();
    history.push('/login')
    return {type: userConstants.LOGOUT};
}


function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GETALL_REQUEST}
    }

    function success(users) {
        return {type: userConstants.GETALL_SUCCESS, users}
    }

    function failure(error) {
        return {type: userConstants.GETALL_FAILURE, error}
    }
}


function rounds() {
    return dispatch => {
        dispatch(request());

        userService.rounds()
            .then(
                rounds => dispatch(success(rounds)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_ROUNDS_REQUEST}
    }

    function success(rounds) {
        return {type: userConstants.GET_ROUNDS_SUCCESS, rounds}
    }

    function failure(error) {
        return {type: userConstants.GET_ROUNDS_FAILURE, error}
    }
}


function getCurrentRound() {
    return dispatch => {
        dispatch(request());

        return userService.getCurrentRound()
            .then(
                round => {
                    dispatch(success(round))
                    return dispatch(success(round))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_CURRENTROUND_REQUEST}
    }

    function success(round) {
        return {type: userConstants.GET_CURRENTROUND_SUCCESS, round}
    }

    function failure(error) {
        return {type: userConstants.GET_CURRENTROUND_FAILURE, error}
    }
}




function getCurrentRoundForHome() {
    return dispatch => {
        dispatch(request());

        return userService.getCurrentRoundForHome()
            .then(
                round_for_home => {
                    dispatch(success(round_for_home))
                    return dispatch(success(round_for_home))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_CURRENTROUNDFORMHOME_REQUEST}
    }

    function success(round_for_home) {
        return {type: userConstants.GET_CURRENTROUNDFORMHOME_SUCCESS, round_for_home}
    }

    function failure(error) {
        return {type: userConstants.GET_CURRENTROUNDFORMHOME_FAILURE, error}
    }
}

function getRoundVoting() {
    return dispatch => {
        dispatch(request());

        userService.getRoundVoting()
            .then(
                voting => dispatch(success(voting)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_CURRENTROUNDVOTING_REQUEST}
    }

    function success(voting) {
        return {type: userConstants.GET_CURRENTROUNDVOTING_SUCCESS, voting}
    }

    function failure(error) {
        return {type: userConstants.GET_CURRENTROUNDVOTING_FAILURE, error}
    }
}

function getRevotedUsers() {
    return dispatch => {
        dispatch(request());

      return  userService.getRevotedUsers()
            .then(
                revote_users => {
                    dispatch(success(revote_users))
                    return dispatch(success(revote_users))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_CURRENTROUNDREVOTE_REQUEST}
    }

    function success(revote_users) {
        return {type: userConstants.GET_CURRENTROUNDREVOTE_SUCCESS, revote_users}
    }

    function failure(error) {
        return {type: userConstants.GET_CURRENTROUNDREVOTE_FAILURE, error}
    }
}


function getRoundResult(id) {
    return dispatch => {
        dispatch(request());

        return userService.getRoundResult(id)
            .then(
                result => {
                    dispatch(success(result))
                    return dispatch(success(result))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_RESULT_REQUEST}
    }

    function success(result) {
        return {type: userConstants.GET_RESULT_SUCCESS, result}
    }

    function failure(error) {
        return {type: userConstants.GET_RESULT_FAILURE, error}
    }
}


function getUsersForSpecialPrice() {
    return dispatch => {
        dispatch(request());

        userService.getUsersForSpecialPrice()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_GETUSERSFORSPECIALPRICE_REQUEST}
    }

    function success(users) {
        return {type: userConstants.GET_GETUSERSFORSPECIALPRICE_SUCCESS, users}
    }

    function failure(error) {
        return {type: userConstants.GET_GETUSERSFORSPECIALPRICE_FAILURE, error}
    }
}


function getServerDate() {
    return dispatch => {
        dispatch(request());

        return userService.getServerDate()
            .then(
                date => {
                    dispatch(success(date))
                    return dispatch(success(date))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_SERVERTIME_REQUEST}
    }

    function success(date) {
        return {type: userConstants.GET_SERVERTIME_SUCCESS, date}
    }

    function failure(error) {
        return {type: userConstants.GET_SERVERTIME_FAILURE, error}
    }
}

function getBudges() {
    return dispatch => {
        dispatch(request());

        userService.getBudges()
            .then(
                res => dispatch(success(res)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() {
        return {type: userConstants.GET_BUDGES_REQUEST}
    }

    function success(res) {
        return {type: userConstants.GET_BUDGES_SUCCESS, res}
    }

    function failure(error) {
        return {type: userConstants.GET_BUDGES_FAILURE, error}
    }
}


function forgotPass(email, url) {
    return dispatch => {
        dispatch(request({email}));

        userService.forgotPass(email, url)
            .then(
                users => {
                    dispatch(success(users));
                },
                error => dispatch(failure(error.toString()))
            )
        ;
    };

    function request() {
        return {type: userConstants.FORGOTPASS_REQUEST}
    }

    function success(users) {
        return {type: userConstants.FORGOTPASS_SUCCESS, users}
    }

    function failure(error) {
        return {type: userConstants.FORGOTPASS_FAILURE, error}
    }
}


function addNotes(note, jury_id, round_id, user_id) {
    return dispatch => {
        dispatch(request(note, jury_id, round_id, user_id));

        userService.addNotes(note, jury_id, round_id, user_id)
            .then(
                notes => {
                    dispatch(success(notes));
                },
                error => dispatch(failure(error.toString()))
            )
        ;
    };

    function request(notes) {
        return {type: userConstants.ADD_NOTES_REQUEST, notes}
    }

    function success(notes) {
        return {type: userConstants.ADD_NOTES_SUCCESS, notes}
    }

    function failure(error) {
        return {type: userConstants.ADD_NOTES_FAILURE, error}
    }
}


function finishPerformance(jury_id, performance_id) {
    return dispatch => {
        dispatch(request(jury_id, performance_id));

        return userService.finishPerformance(jury_id, performance_id)
            .then(
                finish => {
                    dispatch(success(finish));
                    return dispatch(success(finish))
                },
                error => dispatch(failure(error.toString()))
            )
            ;
    };

    function request(finish) {
        return {type: userConstants.ADD_FINISHPERF_REQUEST, finish}
    }

    function success(finish) {
        return {type: userConstants.ADD_FINISHPERF_SUCCESS, finish}
    }

    function failure(error) {
        return {type: userConstants.ADD_FINISHPERF_FAILURE, error}
    }
}


function submitVoting(result) {
    return dispatch => {
        dispatch(request(result));

        userService.submitVoting(result)
            .then(
                notes => {
                    dispatch(success(notes));
                },
                error => dispatch(failure(error.toString()))
            )
        ;
    };

    function request(votes) {
        return {type: userConstants.ADD_VOTING_REQUEST, votes}
    }

    function success(votes) {
        return {type: userConstants.ADD_VOTING_SUCCESS, votes}
    }

    function failure(error) {
        return {type: userConstants.ADD_VOTING_FAILURE, error}
    }
}



function submitRevoting(result) {
    return dispatch => {
        dispatch(request(result));

       return userService.submitRevoting(result)
            .then(
                revoting => {
                    dispatch(success(revoting));
                    return dispatch(success(revoting))
                },
                error => dispatch(failure(error.toString()))
            )
        ;
    };

    function request(revoting) {
        return {type: userConstants.ADD_REVOTING_REQUEST, revoting}
    }

    function success(revoting) {
        return {type: userConstants.ADD_REVOTING_SUCCESS, revoting}
    }

    function failure(error) {
        return {type: userConstants.ADD_REVOTING_FAILURE, error}
    }
}

function resetPassword(token, password) {
    return dispatch => {
        dispatch(request({token}));

        userService.resetPassword(token, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/');
                },
                error => dispatch(failure(error.toString()))
            )
        ;
    };

    function request() {
        return {type: userConstants.RESETPASSWORD_REQUEST}
    }

    function success(user) {
        return {type: userConstants.RESETPASSWORD_SUCCESS, user}
    }

    function failure(error) {
        return {type: userConstants.RESETPASSWORD_FAILURE, error}
    }
}
