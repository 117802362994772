import React, {Fragment} from 'react';
import Countdown from "react-countdown";
import {userActions} from "../actions";
import connect from "react-redux/es/connect/connect";


const place_result = [
    {
        first_name: "DIANA",
        last_name: "ADAMYAN",
        country: "ARMENIA",
        place: "1<sup>st</sup> Prize",
        avatar: "https://khic2020.codics.solutions/img/contestants/14.jpg"
    },
    {
        first_name: "MICHAEL",
        last_name: "GERMER",
        country: "DENMARK",
        place: "2<sup>nd</sup> Prize",
        avatar: "https://khic2020.codics.solutions/img/contestants/16.jpg"
    },
    {
        first_name: "LORENZ",
        last_name: "KARLS",
        country: "SWEDEN",
        place: "3<sup>rd</sup> Prize",
        avatar: "https://khic2020.codics.solutions/img/contestants/10.jpg"
    },
    {
        first_name: "BELLE",
        last_name: "TING",
        country: "TAIWAN",
        place: "3<sup>rd</sup> Prize",
        avatar: "https://khic2020.codics.solutions/img/contestants/1.jpg"
    },
    {
        first_name: "FELIKS",
        last_name: "HARUTYUNYAN",
        country: "ARMENIA",
        place: "Laureate Diploma",
        avatar: "https://khic2020.codics.solutions/img/contestants/6.jpg"
    },
]

class ThankYou extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toResult: false
        }
    }

    componentDidMount() {
        const {round} = this.props;
        let round_id = round.id;
        // this.props.getResult(3)
    }

    changeState = () => {
        this.setState({
            toResult: true
        })
    }

    render() {
        const {timer, activeState, isTop, result, round} = this.props
        const {toResult} = this.state
        console.log(timer)
        console.log(result && result)
        return (
            <div className="container result-page">
                {/*<div className="row">*/}
                {/*<div className="col-md-8">*/}
                {/*<h1 className="thankyou-title" style={{fontSize: '3vw'}}>*/}
                {/*YOUR VOTING PERIOD IS OVER.*/}
                {/*<br/>*/}
                {/*THANK YOU FOR VOTE.*/}
                {/*</h1>*/}
                {/*<h3 className="thankyou-title-small" style={{fontSize: '1.8vw'}}>*/}
                {/*THE PRIZE WINNERS OF THE 16TH KHACHATURIAN INTERNATIONAL COMPETITION WILL BE ANNOUNCED AT <br/>*/}
                {/*10:00 PM (ARMENIAN STANDARD TIME: GMT/UTC+4)*/}
                {/*</h3>*/}
                {/*<div className="thankyou-countDown">*/}
                {/*<Countdown*/}
                {/*onComplete={this.changeState.bind(this)}*/}
                {/*date={Date.now() + timer}/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div className="col-md-4">*/}
                {/*<img className="thankyou-img" src="/img/Violin_reflected.png" alt=""/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*{(activeState !== 'voting_result' && !toResult) && (*/}
                {/*<Fragment>*/}
                {/*{(round.id === 1 || round.id === 2) && (*/}
                {/*<div className="row">*/}
                {/*<div className="col-md-8">*/}
                {/*<h1 className="thankyou-title" style={{fontSize: '3vw'}}>*/}
                {/*YOUR VOTING PERIOD IS OVER.*/}
                {/*<br/>*/}
                {/*THANK YOU FOR VOTE.*/}
                {/*</h1>*/}
                {/*<h3 className="thankyou-title-small" style={{fontSize: '1.8vw'}}>*/}
                {/*THE WINNERS OF*/}
                {/*THE <span>{round.id}</span><sup>{round.id === 1 ? 'ST' : round.id === 2 ? 'ND' : ''}</sup> ROUND*/}
                {/*WILL BE ANNOUNCED AT 11 PM (ARMENIAN STANDARD TIME:*/}
                {/*GMT/UTC+4)*/}
                {/*</h3>*/}
                {/*<div className="thankyou-countDown">*/}
                {/*<Countdown*/}
                {/*onComplete={this.changeState.bind(this)}*/}
                {/*date={Date.now() + timer}/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div className="col-md-4">*/}
                {/*<img className="thankyou-img" src="/img/Violin_reflected.png" alt=""/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*)}*/}
                {/*{round.id === 3 && (*/}
                {/*<div className="row">*/}
                {/*<div className="col-md-8">*/}
                {/*<h1 className="thankyou-title" style={{fontSize: '3vw'}}>*/}
                {/*YOUR VOTING PERIOD IS OVER.*/}
                {/*<br/>*/}
                {/*THANK YOU FOR VOTE.*/}
                {/*</h1>*/}
                {/*<h3 className="thankyou-title-small" style={{fontSize: '1.8vw'}}>*/}
                {/*The prize winners of the 16th Khachaturian International Competition will be*/}
                {/*announced at 10PM (Armenian Standard Time: GMT/UTC+4).*/}
                {/*<br/>*/}
                {/*<br/>*/}
                {/*Closing Concert will follow.*/}
                {/*</h3>*/}
                {/*<div className="thankyou-countDown">*/}
                {/*<Countdown*/}
                {/*onComplete={this.changeState.bind(this)}*/}
                {/*date={Date.now() + timer}/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*<div className="col-md-4">*/}
                {/*<img className="thankyou-img" src="/img/Violin_reflected.png" alt=""/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {/*)}*/}
                {/*</Fragment>*/}
                {/*)}*/}
                {/*{(activeState === 'voting_result' || toResult) && (*/}
                {/*<div className="row">*/}
                {/*<div className="col-md-12" style={{marginBottom: '7%', marginTop: '-5%'}}>*/}
                {/*{round.id === 1 && (*/}
                {/*<h1 className="blue" style={{fontSize: '2.7vw', textTransform: 'uppercase'}}>*/}
                {/*Contestants admitted*/}
                {/*<br/>*/}
                {/*to the 2<sup>nd</sup> round*/}
                {/*</h1>*/}
                {/*)}*/}
                {/*{round.id === 2 && (*/}
                {/*<h1 className="blue" style={{fontSize: '2.7vw', textTransform: 'uppercase'}}>*/}
                {/*Contestants admitted*/}
                {/*<br/>*/}
                {/*to the 3<sup>rd</sup> round*/}
                {/*</h1>*/}
                {/*)}*/}
                {/*{round.id === 3 && (*/}
                {/*<h1 className="blue" style={{fontSize: '2.7vw', textTransform: 'uppercase'}}>*/}
                {/*Prize Winners*/}
                {/*</h1>*/}
                {/*)}*/}
                {/*</div>*/}

                <div className="row">
                    <div className="col-md-12">
                        <h1 className="thankyou-title" style={{fontSize: '3vw', marginBottom: '11%'}}>
                            Prize Winners of the 16th Khachaturian International Competition
                        </h1>
                    </div>
                    {place_result.map((el, id) => {
                        return (
                            <div className={'col-md-4 result-blocks'} key={id}>
                                <div className="trustee">
                                    <div className="trustee-img"
                                         style={{backgroundImage: `url(${el.avatar})`}}>
                                        <span className="place-winners" dangerouslySetInnerHTML={{__html: el.place}}/>
                                    </div>
                                    <div className="trustee-text">
                                        <h5 className="mb-0">{el.first_name} {el.last_name}</h5>
                                        <span> {el.country}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="col-md-12">
                        <h1 className="thankyou-title" style={{fontSize: '3vw', marginBottom: '11%'}}>
                            Nominees
                        </h1>
                    </div>
                    <div className="container">
                        <h2 className="scheduled-dates-title grey mb-4">Nominees</h2>
                        <div className="row pt-4">
                            <div className="col-md-6" style={{marginBottom: "10rem"}}>
                                <div className="trustee" data-index="13">
                                    <div className="trustee-img"
                                         style={{
                                             backgroundImage: "url('https://khic2020.codics.solutions/img/contestants/14.jpg')",
                                             height: "29vh"
                                         }}></div>
                                    <div className="trustee-text">
                                        <h5 className="mb-0">Diana Adamyan</h5>
                                        <span>Armenia</span>
                                        <p>Best interpretation of a composition for solo violin by Johann Sebastian Bach
                                            (1st Round) <br/><br/> Best interpretation of the Dance by Aram Khachaturian
                                            (2nd Round)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 " style={{marginBottom: "10rem"}}>
                                <div className="trustee" data-index="15">
                                    <div className="trustee-img"
                                         style={{
                                             backgroundImage: "url('https://khic2020.codics.solutions/img/contestants/16.jpg')",
                                             height: "29vh"
                                         }}></div>
                                    <div className="trustee-text">
                                        <h5 className="mb-0">Michael Germer</h5>
                                        <span>Denmark</span>
                                        <p>Best interpretation of a Caprice by Niccolò Paganini (1st Round)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6" style={{marginBottom: "10rem"}}>
                                <div className="trustee" data-index="0">
                                    <div className="trustee-img"
                                         style={{
                                             backgroundImage: "url('https://khic2020.codics.solutions/img/contestants/1.jpg')",
                                             height: "29vh"
                                         }}></div>
                                    <div className="trustee-text">
                                        <h5 className="mb-0">Belle Ting</h5>
                                        <span>Taiwan</span>
                                        <p>Best interpretation of the Song-poem by Aram Khachaturian (1st Round)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 " style={{marginBottom: "10rem"}}>
                                <div className="trustee" data-index="9">
                                    <div className="trustee-img"
                                         style={{
                                             backgroundImage: "url('https://khic2020.codics.solutions/img/contestants/10.jpg')",
                                             height: "29vh"
                                         }}></div>
                                    <div className="trustee-text">
                                        <h5 className="mb-0">Lorenz Karls</h5>
                                        <span>Sweden</span>
                                        <p>Best interpretation of a Romance by Ludwig van Beethoven (1st Round)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*</div>*/}
                {/*)}*/}
            </div>
        )
    }
}

function mapState(state) {
    return {
        round: state.currentRound.round,
        result: state.result
    };
}

const actionCreators = {
    getCurrentRound: userActions.getCurrentRound,
    getResult: userActions.getRoundResult
};
export default connect(mapState, actionCreators)(ThankYou)
