import React from 'react';
import {Link} from 'react-router-dom';
import {AvForm, AvGroup, AvInput, AvFeedback} from 'availity-reactstrap-validation';
import {Label} from 'reactstrap';
import {connect} from 'react-redux';
import {userActions} from '../actions';
import {withRouter} from 'react-router-dom';
import {history} from '../helpers';
import Alert from '../components/Alert'

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            submitted: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }


    componentDidMount() {
        if (localStorage.getItem('user')) {
            history.push('/')
        }

    }

    handleValidSubmit(event, values) {
        this.setState({
            email: values.email,
            password: values.password,
            submitted: true,
            validateMail: false
        });
        const {email, password} = this.state;
        if (email && password) {
            this.props.login(email, password);
        }
        setTimeout(() => {
            this.setState({
                submitted: false
            })
        }, 3000)
    }


    onClick = () => {
        this.setState({
            submitted: false
        })
    }

    render() {
        const {loggingIn, fail} = this.props;
        const {email, password, submitted} = this.state;
        return (
            <div className="Login">
                <Alert show={fail && submitted} msg={fail} onClick={e => this.onClick(e)}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="Login-bg" style={{backgroundImage: 'url(/img/cover.png)'}}/>
                        <div className="container content">
                            <div className="row">
                                <div className="col-md-4 my-col-md-4">
                                    <AvForm autoComplete="off" onValidSubmit={this.handleValidSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h2 className="my-title orange">
                                                    JURY LOGIN
                                                </h2>
                                            </div>
                                            <div className="col-md-12">
                                                <AvGroup>
                                                    <AvInput id="mail" name="email" type="email" required
                                                             tabIndex={1} autoComplete="off" value={email}
                                                    />
                                                    <Label className="form-control-label" for="password">
                                                        Email *
                                                    </Label>
                                                    <AvFeedback>This field is invalid</AvFeedback>
                                                </AvGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <AvGroup>
                                                    <AvInput id="password" name="password" type="password"
                                                             required tabIndex={2} autoComplete="off" value={password}
                                                    />
                                                    <Label className="form-control-label" for="password">
                                                        Password *
                                                    </Label>
                                                    <AvFeedback>This field is required</AvFeedback>
                                                </AvGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <Link to="/forgot-password">
                                                    <span className="forgot-link">
                                                        Forgot password?
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="col-md-12">
                                                <button className="my-btn" type="submit">
                                                    {loggingIn ?
                                                        (<img
                                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loader"/>) : (
                                                            <span>
                                                                     LOGIN
                                                                 </span>
                                                        )
                                                    }
                                                </button>

                                            </div>
                                        </div>
                                    </AvForm>
                                </div>
                                <div className="col-md-1"/>
                                <div className="col-md-7 my-col-md-7">
                                    <div className="info">
                                        <div className="row">
                                            <h2 className="my-title">
                                                KHACHATURIAN
                                                <br/>
                                                INTERNATIONAL COMPETITION
                                            </h2>
                                            <p className="small-txt">
                                                <br/>
                                                Since 2003, the Khachaturian International Competition has been
                                                launching on June 6 in tribute to the birth date of the 20th century
                                                prominent Armenian composer Aram Khachaturian.
                                            </p>
                                            <p>
                                                The competition reveals talented young musicians in the categories of
                                                cello, piano, violin and conducting.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const {loggingIn, fail} = state.authentication;
    return {loggingIn, fail};
}

const actionCreators = {
    login: userActions.login,
    logout: userActions.logout
};
export default withRouter(connect(mapState, actionCreators)(Login))
