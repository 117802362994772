import React from 'react';
import {AvForm, AvGroup, AvInput, AvFeedback} from 'availity-reactstrap-validation';
import {Label} from 'reactstrap';
import {connect} from 'react-redux';
import {userActions} from '../actions';
import {withRouter} from 'react-router-dom';
import {history} from '../helpers';

const urlToken = new URLSearchParams(window.location.search)
if(!urlToken.get('token')){
    history.push('/login')
}
class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirm_password: '',
            token: urlToken.get('token'),
            submitted: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }


    componentDidMount() {
        if (localStorage.getItem('user')) {
            history.push('/')
        }
    }

    handleValidSubmit(event, values) {
        this.setState({
            password: values.password,
            confirm_password: values.confirm_password,
            submitted: true
        });
        const {password, confirm_password, token} = this.state;
        if (password === confirm_password) {
            this.props.resetPassword(token, password);
        }
    }

    render() {
        const {mailSendingResetPassword} = this.props;
        const {password} = this.state;
        return (
            <div className="Login">
                <div className="container-fluid">
                    <div className="row">
                        <div className="Login-bg" style={{backgroundImage: 'url(/img/login-bg.png)'}}/>
                        <div className="container content">
                            <div className="row">
                                <div className="col-md-4 my-col-md-4">
                                    <AvForm autoComplete="off" onValidSubmit={this.handleValidSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h2 className="my-title orange">
                                                    CREATE PASSWORD
                                                </h2>
                                            </div>
                                            <div className="col-md-12">
                                                <AvGroup>
                                                    <AvInput id="password" name="password" type="password"
                                                             required tabIndex={1} autoComplete="off" value={password}
                                                    />
                                                    <Label className="form-control-label" for="password">
                                                        Password *
                                                    </Label>
                                                    <AvFeedback>This field is required</AvFeedback>
                                                </AvGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <AvGroup>
                                                    <AvInput id="password" name="confirm_password" type="password"
                                                             required tabIndex={1} autoComplete="off" value={password}
                                                    />
                                                    <Label className="form-control-label" for="password">
                                                        Confirm Password *
                                                    </Label>
                                                    <AvFeedback>This field is required</AvFeedback>
                                                </AvGroup>
                                            </div>
                                            <div className="col-md-12">
                                                <button className="my-btn" type="submit">
                                                    {mailSendingResetPassword ?
                                                        (<img
                                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loader"/>) : (
                                                            <span>
                                                                     SEND
                                                                 </span>
                                                        )
                                                    }
                                                </button>

                                            </div>
                                        </div>
                                    </AvForm>
                                </div>
                                <div className="col-md-1"/>
                                <div className="col-md-7 my-col-md-7">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="note-for-mobile">
                    <h1 className="blue">
                        For better experience please use computer
                    </h1>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const {mailSendingResetPassword} = state.authentication;
    return {mailSendingResetPassword};
}

const actionCreators = {
    resetPassword: userActions.resetPassword
};
export default withRouter(connect(mapState, actionCreators)(ResetPassword))
