import React, {Fragment} from 'react';

class ProfileModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {user, show, closePopup} = this.props;
        const firstRoundPerf = user && user.performances.filter(p => p.round_id === 1)
        const secondRoundPerf = user && user.performances.filter(p => p.round_id === 2)
        return (
            <div className={`winnersPopup ${show && 'show'}`}>
                <div className="winnersPopupCloseLayer" onClick={closePopup}/>
                <div className="popupBody">
                    {(user && user.profile) && (
                        <Fragment>
                            <div className="winnersPopupClose" onClick={closePopup}/>

                            <div className="popupWinnerInfo">
                                <div className="popupWinnerAvatar"
                                     style={{backgroundImage: `url(${user.avatar})`}}/>
                                <p className="popupWinnerName">
                                    {user.first_name.toUpperCase()} {user.last_name.toUpperCase()}
                                </p>
                                <p className="popupWinnerBirth">
                                    Date of birth: {user.profile.date_of_birth && user.profile.date_of_birth}
                                </p>
                                <p className="popupWinnerCountry">
                                    {user.country}
                                </p>
                            </div>

                            <div className="popupWinnerDetails">
                                <div className="popupWinnerDetailsItem">
                                    {user.profile.education && (<p className="popupWinnerDetailsTitle">Education</p>)}
                                    <div className="popupWinnerDetailsBody">
                                        {user.profile.education && user.profile.education.map((item, i) => {
                                            return (
                                                <div style={{marginBottom: '30px'}} key={i}>
                                                    <p className="titleText">{item.country} - {item.city}</p>
                                                    <p className="titleText">{item.start_year} - {item.end_year !== 'null' ? item.end_year : ''}</p>
                                                    <p className="titleText">{item.qualaifications}</p>
                                                    <p className="titleText">{item.teacher}</p>
                                                    <p className="titleText">{item.university}</p>
                                                </div>
                                            )
                                        })}
                                        {user.profile.professors && (
                                            <>
                                                <p className="titleText">Professors</p>
                                                {user.profile.professors.map((item, i) => {
                                                    return (
                                                        <p key={i}>{item}</p>
                                                    )
                                                })}
                                            </>
                                        )}

                                    </div>
                                </div>
                                {user.profile.competitions && (
                                    <div className="popupWinnerDetailsItem">
                                        <p className="popupWinnerDetailsTitle">Competitions</p>
                                        <div className="popupWinnerDetailsBody">
                                            {user.profile.competitions && user.profile.competitions.map((item, i) => {
                                                return (
                                                    <p className="titleText" key={i}>{item}</p>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}


                                <div className="popupWinnerDetailsItem">
                                    {user.profile.composition && (
                                        <p className="popupWinnerDetailsTitle">Composition</p>)}
                                    <div className="popupWinnerDetailsBody">
                                        {user.profile.composition && (
                                            <p className="titleText">{user.profile.composition}</p>)}
                                    </div>
                                </div>

                                <div className="popupWinnerDetailsItem" style={{display: 'none'}}>
                                    <p className="popupWinnerDetailsTitle">Programs</p>
                                    <div className="popupWinnerDetailsBody">
                                        <h4 className="blue"
                                            style={{fontSize: '1vw', fontWeight: '600'}}>1<sup>st</sup> Round</h4>
                                        <br/>
                                        {firstRoundPerf.map((el, i) => {
                                            return (
                                                <p className="titleText" key={i}
                                                   style={{marginBottom: '15px', marginTop: '0', fontWeight: '300'}}>
                                                    {el.title}
                                                </p>
                                            )
                                        })}
                                        <br/>
                                        <h4 className="blue"
                                            style={{fontSize: '1vw', fontWeight: '600'}}>2<sup>nd</sup> Round
                                            (Semi-Final)</h4>
                                        <br/>
                                        {secondRoundPerf.map((el, i) => {
                                            return (
                                                <p className="titleText" key={i}
                                                   style={{marginBottom: '15px', marginTop: '0', fontWeight: '300'}}>
                                                    {el.title}
                                                </p>
                                            )
                                        })}
                                        <br/>
                                        <h4 className="blue"
                                            style={{fontSize: '1vw', fontWeight: '600'}}>3<sup>rd</sup> Round (Final)
                                        </h4>
                                        <br/>
                                        <p className="titleText"
                                           style={{marginBottom: '15px', marginTop: '0', fontWeight: '300'}}>
                                            Aram Khachaturian - Violin Concerto in D minor, op.46
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        )
    }
}

export default ProfileModal;
