import React from 'react';
import {AvForm} from 'availity-reactstrap-validation';
import {userActions} from "../actions";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";

class ComentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            note: {
                text: '',
                badges: [],
                favourite: false
            },
        }
    }

    handleChange = (e, id, type) => {
        const {checked, value} = e.target;
        this.setState(({note: {badges, text, favourite}}) => {
            switch (type) {
                case 'checkbox':
                    if (checked) {
                        badges.push(value)
                    } else {
                        let remove = badges.indexOf(value);
                        if (remove > -1) {
                            badges.splice(remove, 1);
                        }
                    }
                    return {
                        note: {
                            badges: badges,
                            text: text,
                            favourite: favourite
                        }
                    };
                case 'textarea':
                    return {
                        note: {
                            badges: badges,
                            text: value.replace(/\r?\n/g, "<br />"),
                            favourite: favourite
                        }
                    };
                case 'favourite':
                    if (checked) {
                        return {
                            note: {
                                badges: badges,
                                text: text,
                                favourite: true
                            }
                        }
                    }
                    else {
                        return {
                            note: {
                                badges: badges,
                                text: text,
                                favourite: false
                            }
                        }
                    }
                default:
                    break;
            }
        })
    }
    resetState = () => {
        this.setState({
            note: {
                text: '',
                badges: [],
                favourite: false
            }
        })
    }
    comentSubmit = (e, data) => {
        const {user, round, activeUser} = this.props
        let jury_id = user && user.user.id,
            round_id = round && round.id,
            user_id = activeUser.user && activeUser.user.id
        this.props.addNotes(data, jury_id, round_id, user_id);
    }

    render() {
        const {budges,  activeUser} = this.props;
        const {note} = this.state
        return (
            <form
                ref={(el) => this.myFormRef = el} className={`home-form`} id="note-form">
                <div onClick={(e) => this.comentSubmit(e, note)} id="home-form" style={{display: 'none'}}/>
                <div onClick={(e) => this.resetState(e)} id="reset-form" style={{display: 'none'}}/>
                <ul className="tag-list">
                    {(budges && budges.budges) && budges.budges.filter(function (event) {
                        if (event.id === 7) {
                            return; // skip
                        }
                        return true;
                    }).map((item, i) => {
                        return (
                            <li className="tag" key={i}>
                                <label className="custom-checkbox">
                                    <input type="checkbox" name="note.badge" value={item.id}
                                           onChange={(e) => this.handleChange(e, item.id, 'checkbox')}/>
                                    <span className="checkmark">{item.name}</span>
                                </label>
                            </li>
                        )
                    })}
                </ul>
                <div className="jury-notes-block">
                    <div
                        className="col-md-12 d-flex align-items-center justify-content-between">
                        <h5 className="white">MY NOTES</h5>
                        <span style={{fontSize: '11px', color: 'white'}}>
                            <i>
                                Automatically saved
                            </i>
                        </span>
                    </div>
                    <div className="col-md-12">
                        <div>
                                                        <textarea name="" id="" rows="6"
                                                                  className="jury-notes"
                                                                  onChange={(e) => this.handleChange(e, '', 'textarea')}/>
                        </div>
                    </div>
                </div>
                <div className="chose-favourite">
                    <label>
                        <input type="checkbox" className="opacity-0"
                               onChange={(e) => this.handleChange(e, note.favourite, 'favourite')}/>
                        {note.favourite ? (
                            <img src="/img/heart-active.svg" alt=""/>
                        ) : (
                            <img src="/img/heart.svg" alt=""/>
                        )}
                    </label>
                </div>
            </form>
        )
    }
}

function mapState(state) {
    return {
        notes: state.notes,
        round: state.currentRound.round,
        user: state.authentication
    };
}

const actionCreators = {
    addNotes: userActions.addNotes
};
export default withRouter(connect(mapState, actionCreators)(ComentForm))
