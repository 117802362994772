import {combineReducers} from 'redux';

import {authentication} from './authentication.reducer';
import {users} from './users.reducer';
import {rounds} from './users.reducer';
import {alert} from './alert.reducer';
import {schedules} from './users.reducer';
import {budges} from './users.reducer';
import {notes} from './users.reducer';
import {date} from './users.reducer';
import {currentRound} from './users.reducer';
import {currentVoting} from './users.reducer';
import {allUsersForVoting} from './users.reducer';
import {votes} from './users.reducer';
import {finish} from './users.reducer';
import {result} from './users.reducer';
import {currentRoundForHome} from './users.reducer';
import {current_revoting} from './users.reducer';
import {revoting} from './users.reducer';

const rootReducer = combineReducers({
    authentication,
    users,
    alert,
    rounds,
    schedules,
    budges,
    notes,
    date,
    currentRound,
    currentRoundForHome,
    currentVoting,
    current_revoting,
    votes,
    allUsersForVoting,
    finish,
    result,
    revoting
});

export default rootReducer;
