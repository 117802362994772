import React, {Fragment} from 'react'

import UserNoteCollapse from "../components/UserNoteCollapse";
import connect from "react-redux/es/connect/connect";
import {userActions} from "../actions";
import ProfileModal from "./ProfileModal";


// const Video = React.forwardRef((props, ref) => <video ref={ref}/>);

class Revoting extends React.Component {
    videoRefs = [];

    constructor(props) {
        super(props)
        this.state = {
            collapse: false,
            voting: [],
            no_length: 0,
            disable_no: false,
            yes_length: 0,
            isFavourite: false,
            filter: [],
            playBtn: [],
            currentPlayList: [],
            disableSubmitBtn: true,
            userId: 0,
            openModal: false,
            votingForRound3: [],
            votingForSpecialPrice: [],
            disable_yes_maybe: false,
            prizeVoting: false,
            selectMenu: ''
            // activeState: this.props.round && ((this.props.round.schedules.find(x => x.state === 'now_playing') && this.props.round.schedules.find(x => x.state === 'now_playing').event) || (this.props.round.schedules.find(x => x.state === 'waiting') && this.props.round.schedules.find(x => x.state === 'waiting').event))
        }
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.getRevotedUsers()
        }, 4000)
    }

    handleChange = (e, round_id, user_id, jury_id, vote_label, vote_value) => {
        const rule = 1
        const id = user_id;
        const {voting} = this.state;
        const data = this.props.revote;
        const no = rule;
        let no_choosen;
        let yes_maybe_choosen;
        const vote = {
            round_id: round_id,
            user_id: user_id,
            jury_id: jury_id,
            vote_label: vote_label,
            vote_value: vote_value
        };
        let votes;
        if (e.target.checked) {
            if (voting.some(vote => vote.user_id === id)) {
                votes = [...voting.filter(votes => votes.user_id !== id), vote]
            } else {
                votes = [...voting, vote]
            }
            this.setState({
                voting: votes
            }, () => {
                no_choosen = this.state.voting.filter(x => x.vote_label === 'no');
                yes_maybe_choosen = this.state.voting.filter(x => x.vote_label === 'yes' || x.vote_label === 'maybe');
                this.setState({
                    no_length: no_choosen.length,
                    yes_length: yes_maybe_choosen.length

                })
                if (this.state.voting.length === 1) {
                    this.setState({
                        disableSubmitBtn: false
                    })
                } else {
                    this.setState({
                        disableSubmitBtn: true
                    })
                }
            })
        } else {
            const delElIndex = voting.findIndex(el => el.user_id === user_id)
            const newVotes = [
                ...voting.slice(0, delElIndex),
                ...voting.slice(delElIndex + 1)
            ]
            this.setState({
                voting: newVotes
            }, () => {
                no_choosen = this.state.voting.filter(x => x.vote_label === 'no');
                yes_maybe_choosen = this.state.voting.filter(x => x.vote_label === 'yes' || x.vote_label === 'maybe');
                this.setState({
                    no_length: no_choosen.length,
                    yes_length: yes_maybe_choosen.length

                })
                if (this.state.voting.length === 1) {
                    this.setState({
                        disableSubmitBtn: false
                    })
                } else {
                    this.setState({
                        disableSubmitBtn: true
                    })
                }
            })
        }


    };
    handleChangeFilter = (e, id) => {
        const {checked, value} = e.target;
        if (checked) {
            if (id === 7) {
                this.setState({
                    isFavourite: true
                })
            } else {
                this.setState(prevState => ({
                    filter: [...prevState.filter, value]
                }))
            }
        } else if (id === 7) {
            this.setState({
                isFavourite: false
            })
        }
        else if (id !== 7) {
            let array = [...this.state.filter]; // make a separate copy of the array
            let index = array.indexOf(value)
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({filter: array});
            }
        }
    }
    setRef = (ref) => {
        this.videoRefs.push(ref);
    };
    playVideo = (e, url, id, idx, index) => {
        const {currentPlayList} = this.state;
        this.videoRefs.map((item, i) => {
            item.pause();
        })
        this.videoRefs[id].src = url;
        this.videoRefs[id].play();
        const playList = {
            id: index,
            user: idx
        }
        let play;
        if (currentPlayList.some(playList => playList.user === idx)) {
            play = [...currentPlayList.filter(play => play.user !== idx), playList]
        } else {
            play = [...currentPlayList, playList]
        }

        this.setState(prevState => ({
            currentPlayList: play,
            playBtn: [...prevState.playBtn, id]
        }))
    }
    playFirstVideo = (e, id, url, user) => {
        const playList = {
            id: 0,
            user: user
        }
        let play;
        const {currentPlayList} = this.state;
        play = [...currentPlayList, playList]
        this.videoRefs.map((item, i) => {
            item.pause();
        })
        this.videoRefs[id].src = url;
        this.videoRefs[id].play();
        this.setState(prevState => ({
            currentPlayList: play,
            playBtn: [...prevState.playBtn, id]
        }))
    }
    openModal = (e, id) => {
        this.setState({
            openModal: !this.state.openModal,
            userId: id
        })
    }


    render() {
        const {round_number, budges, user, revote, votes, revoting} = this.props;
        const {no_length, disable_no, isFavourite, yes_length, filter, playBtn, currentPlayList, openModal, disableSubmitBtn, votingForRound3, votingForSpecialPrice, userId, voting, selectMenu, prizeVoting} = this.state;
        let no_lengthForDiable = this.state.voting.filter(x => x.vote_label === 'no').length;
        let yesMaybe_lengthForDiable = this.state.voting.filter(x => x.vote_label === '1').length
        const disabledNo = no_lengthForDiable === 2
        const disabledYesMaybe = yesMaybe_lengthForDiable === 1;
        console.log(revoting)
        return (
            <div>
                {revote && (
                    <Fragment>
                        <ProfileModal user={revote.users[userId]} show={openModal}
                                      closePopup={(e) => this.openModal(e)}/>
                        <div className="container-fluid voting-header">
                            <div className="container voting-info">
                                <div className="row align-items-center">
                                    <div className="col-md-7">
                                        <div className="row align-items-center">
                                            <div className="col-md-2">
                                                <h6>
                                                    ORDER BY
                                                </h6>
                                            </div>
                                            <div className="col-md-9">
                                                <ul className="tag-list">
                                                    {budges.budges && budges.budges.map((item, i) => {
                                                        return (
                                                            <li className="tag" key={i}>
                                                                <label className="custom-checkbox">
                                                                    <input type="checkbox" name="note.badge"
                                                                           value={item.id}
                                                                           onChange={(e) => this.handleChangeFilter(e, item.id)}/>
                                                                    <span
                                                                        className="checkmark">{item.name}</span>
                                                                </label>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="row align-items-center justify-content-end">
                                            <div className="col-md-3">
                                                <button className="submit-voting" disabled={disableSubmitBtn}
                                                        onClick={(e) => this.props.handleSubmitRevoting(e, {votes: voting})}
                                                        data-text={`Please make sure you select exactly 1 YES/MAYBE and 2 NO options`}>
                                                    {(revoting && revoting.loading) ? (
                                                        <img
                                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                                    ) : (<span>SUBMIT</span>)}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <form action="" className="row" onSubmit={this.handleSubmitRevoting}>
                                        {revote && revote.users.map((item, id) => {
                                            return (
                                                <div className="col-md-4" key={id}
                                                     style={{
                                                         order: `${item.notes[0] && (isFavourite && item.notes[0].note.favourite) ? '1' : item.notes[0] && (item.notes[0].note.badges.some(item => filter.includes(item))) ? '2' : (id + 3)}`
                                                     }}>
                                                    <div className="participant-block">
                                                        <div className="user-tags">
                                                            <ul className="participant-tags">
                                                                {item.notes[0] && item.notes[0].note.badges.map((itm, idx) => {
                                                                    return (
                                                                        <li key={idx}>
                                                                            <p>
                                                                                {filter.includes(itm) && (
                                                                                    <span
                                                                                        className="active-tag"/>
                                                                                )}
                                                                                <span>
                                                                                    {budges.budges && budges.budges.find(t => t.id === parseInt(itm))['name'].toUpperCase()}
                                                                                </span>
                                                                            </p>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                        <div className="participant-video">
                                                            {item.notes[0] && item.notes[0].note.favourite && (
                                                                <div className="chose-favourite">
                                                                    <img src="/img/heart-active.svg" alt=""/>
                                                                </div>
                                                            )}
                                                            <div className="user-videos-list">
                                                                <ul>
                                                                    {item.performances.map((el, index) => {
                                                                        return (
                                                                            <li key={index}
                                                                                onClick={e => this.playVideo(e, el.url, id, item.id, index)}
                                                                                className={`${currentPlayList && (currentPlayList.some(x => x.id === index && x.user === item.id)) && 'active'}`}>
                                                                                {currentPlayList && (currentPlayList.some(x => x.id === index && x.user === item.id)) ? (
                                                                                    <span
                                                                                        className="active-tag"/>
                                                                                ) : (
                                                                                    <span></span>
                                                                                )}
                                                                                <span>{`Video ${index + 1}`}</span>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                            <div
                                                                className={` video-play ${playBtn.some(x => x === id) && 'hide'}`}
                                                                onClick={e => this.playFirstVideo(e, id, item.performances[0].url, item.id)}/>
                                                            <video controls
                                                                   src={item.performances[0] && item.performances[0].url}
                                                                   ref={this.setRef}/>
                                                        </div>
                                                        <UserNoteCollapse
                                                            note={item.notes[0] && item.notes[0].note.text}/>
                                                        <div className="participant-info">
                                                            <div className="w-100 d-flex align-items-center">
                                                                <div className="avatar">
                                                                    <img src={item.avatar} alt=""/></div>
                                                                <div
                                                                    className="d-inline-block participant-prof">
                                                                    <div className="participant-name">
                                                                        {item.first_name} {item.last_name}
                                                                    </div>
                                                                    <div className="participant-country">
                                                                        {item.country}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="participant-more"
                                                                 onClick={(e) => this.openModal(e, id)}>
                                                                <span className="participant-more-btn"/>
                                                            </div>
                                                        </div>
                                                        <div className="participant-voting row">
                                                            <div className="col-md-4"></div>
                                                            {revote && revote.vote_rules.map((itm, i) => {
                                                                return (
                                                                    <div className="col-md-4" key={i}>
                                                                        <label
                                                                            className={disabledNo && (itm.vote_label === 'no' && !voting.some(x => x.user_id === item.id && x.vote_value === itm.vote_value)) ? 'voting-checkbox disabled' : (((itm.vote_label === '1' || itm.vote_label === '1') && disabledYesMaybe) && (!voting.some(x => x.user_id === item.id && x.vote_value === itm.vote_value))) ? 'voting-checkbox disabled' : 'voting-checkbox'}>
                                                                            <input type="checkbox"
                                                                                   name={`${item.id}_yes_no_maybe`}
                                                                                   value={itm.vote_value}
                                                                                   onChange={e => this.handleChange(e, revote.id, item.id, user.id, itm.vote_label, itm.vote_value)}
                                                                                   checked={voting && (voting.some(x => x.user_id === item.id && x.vote_value === itm.vote_value))}
                                                                                   disabled={((itm.vote_label === 'no' && disabledNo) && (!voting.some(x => x.user_id === item.id && x.vote_value === itm.vote_value))) || (((itm.vote_label === '1' || itm.vote_label === '1') && disabledYesMaybe) && (!voting.some(x => x.user_id === item.id && x.vote_value === itm.vote_value)))}
                                                                            />
                                                                            <span
                                                                                className={'checkmark blue'}>
                                                                <span className="checkmark-txt">
                                                                    {itm.vote_label}<sup>st</sup> Prize
                                                                </span>
                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })}
                                                            <div className="col-md-4"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        )
    }
}

function mapState(state) {
    return {
        user: state.authentication.user,
        votes: state.votes,
        revoting: state.revoting,
        revote: state.current_revoting.revote_users
    }
}

const actionCreators = {
    submitVoting: userActions.submitVoting,
    getRevotedUsers: userActions.getRevotedUsers
};
export default connect(mapState, actionCreators)(Revoting);
