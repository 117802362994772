import React, {Fragment} from 'react';
import Hls from "hls.js";
import $ from "jquery";

class LiveVideoPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            connectionStatus: true
        }
    }

    componentDidMount() {

        var video = document.getElementById('liveVideoPlayer');
        var videoSrc = 'http://live.codics.solutions:5443/WebRTCAppEE/streams/124176828273894016870219.m3u8';
        if (Hls.isSupported()) {
            var hls = new Hls();
            hls.loadSource(videoSrc);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED, function () {
                video.play();
            });
        }
        else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = videoSrc;
            video.addEventListener('loadedmetadata', function () {
                video.play();
            });
        }
        let connectionstatus = false
        setInterval(() => {
            $.get('https://live.codics.solutions:5443/WebRTCAppEE/streams/116627096410722535208102.m3u8')
                .done(function () {
                    console.log('live on');
                    if (!connectionstatus) {
                        connectionstatus = true;
                        if (Hls.isSupported()) {
                            videoSrc = 'https://live.codics.solutions:5443/WebRTCAppEE/streams/116627096410722535208102.m3u8';
                            hls = new Hls();
                            hls.loadSource(videoSrc);
                            hls.attachMedia(video);
                            hls.on(Hls.Events.MANIFEST_PARSED, function () {
                                video.play();
                            });
                            //
                            //
                            // hls.attachMedia(video);
                            //
                        }
                        else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                            video.src = videoSrc;
                            video.addEventListener('loadedmetadata', function () {
                                video.play();
                            });
                        }
                    }

                })
                .fail(function () {
                    console.log('live off');
                    if (connectionstatus) {
                        connectionstatus = false
                    }
                });
        }, 6000);


    }

    videoTimeUpdate = () => {
        let vid = document.getElementById("liveVideoPlayer") !== null && document.getElementById("liveVideoPlayer");

        function videoTimeUpdate(e) {
            vid.setAttribute("controls", "controls");
        }

        vid.addEventListener('timeupdate', videoTimeUpdate, false);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.activateVideo === nextProps.activateVideo) {
            console.log(this.props.activateVideo)
            return false
        }
        else {
            return true
        }
    }

    // connectionChecker = () => {
    //     $.get(liveLinks[currentVideoLng])
    //         .done(function () {
    //             console.log('live on');
    //             if (!connectionStatus) {
    //                 this.setStet({
    //                     connectionStatus: true
    //                 })
    //                 if (window.location.href.includes('live')) {
    //                     if (Hls.isSupported()) {
    //                         setVideo(liveLinks[currentVideoLng]);
    //                     } else {
    //                         setVideoDefault(liveLinks[currentVideoLng]);
    //                     }
    //                 }
    //                 displayLivePoint(true);
    //             }
    //         })
    //         .fail(function () {
    //             console.log('live off');
    //             if (connectionStatus) {
    //                 this.setStet({
    //                     connectionStatus: true
    //                 })
    //                 if (window.location.href.includes('live')) {
    //                     connectionOff();
    //                 }
    //                 displayLivePoint(false);
    //             }
    //         });
    // }

    render() {
        console.log('asd')
        const {activateVideo} = this.props
        return (
            <Fragment>
                <video
                    id="liveVideoPlayer"
                    className="LiveVideo-player"
                    preload="auto"
                    crossOrigin="true"
                    controls
                    autoPlay
                    playsinline="true"
                    onPlaying={e => this.videoTimeUpdate(e)}
                    onPlay={e => this.videoTimeUpdate(e)}
                >
                    <source src="" type='application/x-mpegURL'/>
                </video>
            </Fragment>
        );
    }
}

export default LiveVideoPlayer;
