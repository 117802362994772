import React from 'react';

class Alert extends React.Component {
    render() {
        const {msg,show,onClick} = this.props
        return (
            <div className={show ? `alert-container show` : `alert-container`}>
                <div className="alert-wrapper">
                    <span className="alert-closeBtn" onClick={onClick}/>
                    <p className="alert-msg">
                        {msg}
                    </p>
                </div>
            </div>
        )
    }
}

export default Alert;
