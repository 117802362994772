import { alertConstants } from '../constants';

export const alertActions = {
    success,
    error,
    clear
};

function success(status) {
    return { type: alertConstants.SUCCESS, status };
}

function error(status) {
    return { type: alertConstants.ERROR, status };
}

function clear() {
    return { type: alertConstants.CLEAR };
}
