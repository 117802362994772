import React from 'react';
import {Route, Redirect} from 'react-router-dom';


export const PrivateRoute = ({component: Component, closeModal: closeModal, startCompTime: startCompTime, activeUser: activeUser, round, startVoting: startVoting, budges: budges, voting: voting, activeState, handleSubmit: handleSubmit, handleSubmitRevoting: handleSubmitRevoting, timer: timer, ...rest}) => {
    return (
        <Route {...rest}
               render={props => (localStorage.getItem('user') ?
                       <Component startVoting={startVoting} activeState={activeState}
                                  closeModal={closeModal} handleSubmit={handleSubmit}
                                  handleSubmitRevoting={handleSubmitRevoting} budges={budges} round={round}
                                  timer={timer} voting={voting} startCompTime={startCompTime}
                                  activeUser={activeUser} {...props} /> :
                       <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
               )}/>
    )
};
