import {userConstants} from '../constants';

export function users(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_PERFORMANCES_REQUEST:
            return {
                loading: true
            };
        case userConstants.GET_PERFORMANCES_SUCCESS:

            return {
                items: action.users
            };
        case userConstants.GET_PERFORMANCES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}

export function rounds(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_ROUNDS_REQUEST:
            return {
                items: action.rounds,
                loading: true
            };
        case userConstants.GET_ROUNDS_SUCCESS:
            return {
                items: action.rounds
            };
        case userConstants.GET_ROUNDS_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}


export function schedules(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_SCHEDULES_REQUEST:
            return {
                items: action.res,
                loading: true
            };
        case userConstants.GET_SCHEDULES_SUCCESS:
            return {
                schedules: action.res
            };
        case userConstants.GET_SCHEDULES_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}


export function budges(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_BUDGES_REQUEST:
            return {
                budges: action.res,
                loading: true
            };
        case userConstants.GET_BUDGES_SUCCESS:
            return {
                budges: action.res
            };
        case userConstants.GET_BUDGES_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}


export function notes(state = {}, action) {
    switch (action.type) {
        case userConstants.ADD_NOTES_REQUEST:
            return {
                loading: true
            };
        case userConstants.ADD_NOTES_SUCCESS:
            return {
                notes: action.notes,
                loading: false
            };
        case userConstants.ADD_NOTES_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}


export function date(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_SERVERTIME_REQUEST:
            return {
                loading: true
            };
        case userConstants.GET_SERVERTIME_SUCCESS:
            return {
                date: action.date,
                loading: false
            };
        case userConstants.GET_SERVERTIME_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}


export function currentRound(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_CURRENTROUND_REQUEST:
            return {
                round: action.round,
                loading: true
            };
        case userConstants.GET_CURRENTROUND_SUCCESS:
            return {
                round: action.round,
                loading: false
            };
        case userConstants.GET_CURRENTROUND_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}




export function currentRoundForHome(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_CURRENTROUNDFORMHOME_REQUEST:
            return {
                round_for_home: action.round_for_home,
                loading: true
            };
        case userConstants.GET_CURRENTROUNDFORMHOME_SUCCESS:
            return {
                round_for_home: action.round_for_home,
                loading: false
            };
        case userConstants.GET_CURRENTROUNDFORMHOME_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}

export function currentVoting(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_CURRENTROUNDVOTING_REQUEST:
            return {
                voting: action.voting,
                loading: true
            };
        case userConstants.GET_CURRENTROUNDVOTING_SUCCESS:
            return {
                loading: false,
                voting: action.voting
            };
        case userConstants.GET_CURRENTROUNDVOTING_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}


export function current_revoting(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_CURRENTROUNDREVOTE_REQUEST:
            return {
                revote_users: action.revote_users,
                loading: true
            };
        case userConstants.GET_CURRENTROUNDREVOTE_SUCCESS:
            return {
                loading: false,
                revote_users: action.revote_users
            };
        case userConstants.GET_CURRENTROUNDREVOTE_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}




export function revoting(state = {}, action) {
    switch (action.type) {
        case userConstants.ADD_REVOTING_REQUEST:
            return {
                revoting: action.revoting,
                loading: true
            };
        case userConstants.ADD_REVOTING_SUCCESS:
            return {
                loading: false,
                revoting: action.revoting
            };
        case userConstants.ADD_REVOTING_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}

export function allUsersForVoting(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_GETUSERSFORSPECIALPRICE_REQUEST:
            return {
                users: action.users,
                loading: true
            };
        case userConstants.GET_GETUSERSFORSPECIALPRICE_SUCCESS:
            return {
                loading: false,
                users: action.users
            };
        case userConstants.GET_GETUSERSFORSPECIALPRICE_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}


export function votes(state = {}, action) {
    switch (action.type) {
        case userConstants.ADD_VOTING_REQUEST:
            return {
                loading: true
            };
        case userConstants.ADD_VOTING_SUCCESS:
            return {
                loading: false,
                votes: action.votes
            };
        case userConstants.ADD_VOTING_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}


export function finish(state = {}, action) {
    switch (action.type) {
        case userConstants.ADD_FINISHPERF_REQUEST:
            return {
                loading: true,
                finish:action.finish
            };
        case userConstants.ADD_FINISHPERF_SUCCESS:
            return {
                loading: false,
                finish: action.finish
            };
        case userConstants.ADD_FINISHPERF_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}


export function result(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_RESULT_REQUEST:
            return {
                loading: true,
                result:action.result
            };
        case userConstants.GET_RESULT_SUCCESS:
            return {
                loading: false,
                result: action.result
            };
        case userConstants.GET_RESULT_FAILURE:
            return {
                error: action.error
            };

        default:
            return state
    }
}
