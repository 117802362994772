import React, {Fragment} from 'react';
import Countdown from 'react-countdown';
import VotingBlock from "../components/VotingBlock";
import {Modal} from "react-bootstrap"
import {userActions} from "../actions";
import {withRouter} from "react-router";
import connect from "react-redux/es/connect/connect";

import ThankYou from "../components/ThankYou";
import InstructionModal from "../components/InstructionModal";
import Intercom from "react-intercom";
import ComentForm from "../components/ComentForm";
import LiveVideoPlayer from "../components/LiveVideoPlayer";
import UserInfo from "../components/UserInfo";
import AlertForAutoPlay from "../components/AlertForAutoPlay";
import Hls from 'hls.js'
import Pusher from "pusher-js";
import Revoting from "../components/Revoting";

let nextVideo = null;
let urlArrays = [];


let perfNum = 0

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeUser: '',
            currentVideoId: null,
            hideShowCountdown: true,
            nextVideoWrapper: false,
            showLoader: false,
            countDownForVotingStart: false,
            openModal: false,
            activeState: '',
            removeAllBlocks: false,
            // isDisable: true,
            watchPerf: false,
            modalsHideCount: 0,
            stateRound: [],
            activateVideo: true
        };
    }


    componentDidMount() {//
        let _self = this
        this.props.getCurrentRound().then(res => {
            if (res.type === 'GET_CURRENTROUND_SUCCESS') {

                if(this.props.activeState === 'revoting'){
                    setTimeout(() => {
                        this.props.getRevotedUsers()
                    }, 4000)
                }

                this.props.getBudges();
                const {round, user} = _self.props
                _self.setState({
                    stateRound: round,
                    activeUser: round.schedules.find(u => u.state === 'pasted') || round.schedules.find(u => u.state === 'waiting')
                }
                , () => {
                    // this.props.getDate().then(response => {
                    //     if (response.type === 'GET_SERVERTIME_SUCCESS') {
                    //         const {date} = this.props;
                    //         if ((this.state.activeUser.state === 'waiting' && this.state.activeUser.event === 'performance') || (this.state.activeUser.state === 'waiting' && this.state.activeUser.event === 'break')) {
                    //             this.setState({
                    //                 nextVideoWrapper: true,
                    //                 countDownTimer: new Date(this.state.activeUser.date).getTime() - new Date(date.date.server_date).getTime(),
                    //                 hideShowCountdown: false
                    //             })
                    //         }
                    //         if (this.state.activeUser.state === 'now_playing' && this.state.activeUser.event === 'performance') {
                    //             let noteForm = document.getElementById("note-form-hide") !== null && document.getElementById("note-form-hide")
                    //             noteForm.style.opacity = '1';
                    //             noteForm.style.visibility = 'visible';
                    //
                    //             let userBlock = document.getElementById("note-form-hide") !== null && document.getElementById("user-block")
                    //             userBlock.style.opacity = '1';
                    //             userBlock.style.visibility = 'visible';
                    //             this.setState({
                    //                 nextVideoWrapper: false,
                    //                 hideShowCountdown: false
                    //             })
                    //         }
                    //     }
                    //
                    // });
                })
                let pusher = new Pusher('1d9de322f411b8a9b382', {
                    cluster: 'ap2'
                });
                let channel = pusher.subscribe('khachaturian');
                channel.bind('get_schedule', function (data) {
                    if (data) {
                        if (data.id) {
                            if (data.id && round.schedules.find(u => u.id === data.id).event === 'voting') {
                                window.location.reload()
                            }
                            if (data.id && _self.state.activeUser.user) {
                                let noteForm = document.getElementById("note-form-hide") !== null && document.getElementById("note-form-hide")
                                noteForm.style.opacity = '1';
                                noteForm.style.visibility = 'visible';


                                let userBlock = document.getElementById("note-form-hide") !== null && document.getElementById("user-block")
                                userBlock.style.opacity = '1';
                                userBlock.style.visibility = 'visible';


                                let form = document.getElementById('home-form');
                                let note_form = document.getElementById('note-form') !== null && document.getElementById('note-form');
                                let reset_form = document.getElementById('reset-form');
                                form.click();
                                note_form.reset();
                                reset_form.click();
                            }
                            if (data.id && round.schedules.find(u => u.id === data.id).event !== 'break') {
                                let noteForm = document.getElementById("note-form-hide") !== null && document.getElementById("note-form-hide")
                                noteForm.style.opacity = '1';
                                noteForm.style.visibility = 'visible';


                                let userBlock = document.getElementById("note-form-hide") !== null && document.getElementById("user-block")
                                userBlock.style.opacity = '1';
                                userBlock.style.visibility = 'visible';
                            }
                            if (data.id && round.schedules.find(u => u.id === data.id).event === 'break') {
                                let noteForm = document.getElementById("note-form-hide") !== null && document.getElementById("note-form-hide")
                                noteForm.style.opacity = '0';
                                noteForm.style.visibility = 'hidden';


                                let userBlock = document.getElementById("note-form-hide") !== null && document.getElementById("user-block")
                                userBlock.style.opacity = '0';
                                userBlock.style.visibility = 'hidden';
                            }
                            _self.setState({
                                activeUser: round.schedules.find(u => u.id === data.id),
                                activateVideo: false
                            })

                        }
                        if (data.schedule_update) {

                            _self.props.getCurrentRoundForHome().then(res => {
                                if (res.type === 'GET_CURRENTROUNDFORMHOME_SUCCESS') {
                                    const {round_for_home} = _self.props
                                    _self.setState({
                                        activeUser: round_for_home.schedules.find(u => u.state === 'now_playing') || round_for_home.schedules.find(u => u.state === 'waiting'),
                                        activateVideo: false
                                    })
                                }
                            })
                        }

                    }
                });

            }
        });

    }


    playVideo() {
        let noteForm = document.getElementById("note-form-hide") !== null && document.getElementById("note-form-hide")
        noteForm.style.opacity = '1';
        noteForm.style.visibility = 'visible';
        this.setState({
            nextVideoWrapper: false,
            hideShowCountdown: false
        })
    }

    onHideModals = () => {
        const {round} = this.props;
        this.setState({
            modalsHideCount: this.state.modalsHideCount += 1
        }, () => {
            if (round && (round.id === 1 && round.day === 1) || (round.id === 1 && round.day === 2) || (round.id === 2 && round.day === 1)) {
                // if (this.state.modalsHideCount === 2) {
                //
                // }
            }
            if (round && (round.id === 1 && round.day === 3) || (round.id === 2 && round.day === 2) || (round.id === 3)) {
                // if (this.state.modalsHideCount === 3) {
                //     this.setState({
                //
                //     })
                //     if (this.state.activeState === 'performance') {
                //
                //     }
                //
                // }
            }
        })
    }
    changePageToVoting = () => {
        window.location.reload()
    }




    render() {
        const {closeModal, budges, round, startVoting, notes, user, handleSubmit, handleSubmitRevoting,voting, timer, revote} = this.props;
        const {activeUser, hideShowCountdown, nextVideoWrapper, note, countDownForVotingStart, removeAllBlocks, openModal, showLoader, countDownTimer, activateVideo} = this.state;
        const jury = {
            user_id: user && user.user.id,
            email: user && user.user.email,
            name: (user && user.user.first_name) + ' ' + (user && user.user.last_name)
        };
        return (
            <Fragment>
                <Intercom appID="sde5c5lh" {...jury} />
                <VotingBlock budges={budges} round_number={round && round.round_number.toString()} data={voting && voting}
                             round={round && round}/>

            </Fragment>
        )
    }
}

function mapState(state) {
    return {
        user: state.authentication,
        date: state.date,
        round: state.currentRound.round,
        round_for_home: state.currentRoundForHome.round_for_home,
        voting: state.currentVoting.voting,
        finish: state.finish,
        revote: state.current_revoting.revote_users
    };
}

const actionCreators = {
    getCurrentRound: userActions.getCurrentRound,
    getCurrentRoundForHome: userActions.getCurrentRoundForHome,
    getRoundVoting: userActions.getRoundVoting,
    submitVoting: userActions.submitVoting,
    getBudges: userActions.getBudges,
    getDate: userActions.getServerDate,
    finishPerformance: userActions.finishPerformance,
    getRevotedUsers: userActions.getRevotedUsers
};
export default withRouter(connect(mapState, actionCreators)(Home))

