import React, {Component} from 'react';
import {history} from '../helpers';
import {userActions} from "../actions";
import connect from "react-redux/es/connect/connect";
import Countdown, {zeroPad} from "react-countdown";

class HeaderComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openMenu: false,
            activeState: ''
        }
    }

    toggleMenu = () => {
        this.setState({
            openMenu: !this.state.openMenu
        })
    }

    render() {
        const {user, loggedIn, votingTime, refCallback, activeState, round,revote, voting} = this.props;
        const Completionist = () => <span>Time is over</span>;
        const renderer = ({hours, minutes, seconds, completed}) => {
            if (completed) {
                // Render a completed state
                return <Completionist/>;
            } else {
                // Render a countdown
                return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
            }
        };
        return (
            <header
                className={`header justify-content-center text-center  ${loggedIn && 'bb'} ${((loggedIn && activeState) === 'voting' || (loggedIn && activeState) === 'voting_result' || (loggedIn && activeState) === 'revoting')  && 'fixed'}`}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-5">
                            <div className="row">
                                {loggedIn && (
                                    <div className="text-left col-md-12">
                                        <div className="row align-items-center">
                                            {/*{(activeState === 'voting' || activeState === 'performance' || activeState === 'voting_result') && (*/}
                                            {/*<h5 className="col-md-5" style={{fontSize: '1.2vw'}}>*/}
                                            {/*{round.id}<sup>{round.id === 1 ? 'st' : round.id === 2 ? 'nd' : round.id === 3 ? 'rd' : 'th'}</sup> Round,*/}
                                            {/*Day {round.day}*/}
                                            {/*</h5>*/}
                                            {/*)}*/}
                                            {/*{(activeState === 'voting' || activeState === 'performance' || activeState === 'voting_result' || activeState === 'revoting') && (*/}
                                            {/*    <h5 className="col-md-5" style={{fontSize: '1.2vw'}}>*/}
                                            {/*        3<sup>rd</sup> Round*/}
                                            {/*    </h5>*/}
                                            {/*)}*/}
                                            <h5 className="col-md-5" style={{fontSize: '1.2vw'}}>
                                                {round && round.name}
                                            </h5>
                                            {((activeState === 'voting' && votingTime) && (voting && voting.jury_voted === false))
                                            // || (((activeState === 'revoting' && votingTime) && (revote && revote.jury_voted === false)))
                                            && (
                                                <h6 className="col-md-7 voting-time">
                                                    <p>YOUR VOTING PERIOD IS
                                                        <span style={{
                                                            width: '4vw',
                                                            textAlign: 'center',
                                                            display: 'inline-block'
                                                        }}>
                                                        <Countdown date={votingTime}
                                                                   renderer={renderer}
                                                                   autoStart={false}
                                                                   ref={refCallback}
                                                        />
                                                    </span>
                                                        MINUTES</p>
                                                </h6>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-2">
                            {history.location.pathname === '/login' && (
                                <img className="logo" src="/img/white-logo-new.png" alt=""/>
                            )}
                            {(history.location.pathname !== '/login' && ((activeState === 'voting' || activeState === 'revoting') && (voting && voting.jury_voted === false))) && (
                                <img className="logo" src="/img/K_logo_small_blue@3x.png" alt=""/>
                            )}
                            {((history.location.pathname !== '/login' && (activeState !== 'voting' && activeState !== 'revoting')) || (history.location.pathname !== '/login' && ((activeState === 'voting') && (voting && voting.jury_voted === true)))) && (
                                <img className="logo" src="/img/blue-logo.png" alt=""/>
                            )}
                        </div>
                        <div className="col-md-5">
                            <div className="row">
                                {loggedIn && (
                                    <div className="text-right w-100">
                                        <div className="jury-info">
                                            {activeState === 'performance' && (
                                                <a href="https://khachaturian-competition.com/en/live#competition_schedule"
                                                   target="_blank" className="comp-btn">COMPETITION SCHEDULE</a>
                                            )}
                                            <div className="jury-info-block">
                                                <p className="jury-welcome">
                                                    WELCOME
                                                </p>
                                                <p className="jury-name">
                                                    {user && user.first_name} {user && user.last_name}
                                                </p>
                                            </div>
                                            <div className="jury-first-latter">
                                                {user && user.first_name.charAt(0)}
                                            </div>
                                            <div className="doted-menu">
                                                <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                     focusable="false" className="style-scope yt-icon"
                                                     onClick={e => this.toggleMenu(e)}>
                                                    <g className="style-scope yt-icon">
                                                        <path
                                                            d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                                                            className="style-scope yt-icon"></path>
                                                    </g>
                                                </svg>
                                                <div className={this.state.openMenu ? 'jury-menu show' : 'jury-menu'}>
                                                    <ul>
                                                        <li onClick={this.props.logout}>Log Out</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

function mapState(state) {
    const {loggedIn, user} = state.authentication;
    return {loggedIn, user};
}

const actionCreators = {
    logout: userActions.logout
};
export default connect(mapState, actionCreators)(HeaderComponent)

