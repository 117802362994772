import React from 'react';
import {Collapse} from "react-bootstrap";

class UserNoteCollapse extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            collapse: false,
        }
    }

    render() {
        const {note} = this.props
        return (
            <div className="participant-notes">
                <Collapse in={this.state.collapse}>
                    <div id="example-collapse-text">
                                        <span className="close-collapse"
                                              onClick={() => this.setState({collapse: !this.state.collapse})}/>
                        <span>
                            COMMENT
                            <br/>
                            BOX
                        </span>
                        <span dangerouslySetInnerHTML={{__html: note}}/>
                    </div>
                </Collapse>
            </div>
        )
    }
}

export default UserNoteCollapse;
