export function authHeader() {
    // return authorization header with jwt token
    // let user = JSON.parse(localStorage.getItem('user'));
    let token = JSON.parse(localStorage.getItem('token'));
    if (token) {
        return {
            'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        };
    } else {
        return {};
    }
}
