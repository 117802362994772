import React, {Fragment} from 'react';
import ProfileModal from "./ProfileModal";

class UserInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false
        };
    }
    openModal = (e, id) => {
        this.setState({
            openModal: !this.state.openModal,
            userId: id
        })
    }
    render() {
        const {round, activeUser} = this.props;
        const {openModal} =this.state;
        return (
            <Fragment>
                <ProfileModal user={activeUser && activeUser.user}
                              closePopup={(e) => this.openModal(e)}
                              show={openModal}/>
                <div className={`col-md-7 mt-3`} style={{opacity: '0', visibility: 'hidden'}} id="user-block">
                    <div className="row">
                        <div className={`user-info-block w-100`}
                             style={{position: 'relative'}}
                             onClick={(e) => this.openModal(e, round && round.schedules.indexOf(activeUser))}>
                            <div className="user-avatar">
                                <img src={activeUser && activeUser.user.avatar} alt=""/>
                            </div>
                            <div className="user-info">
                                <h4>
                                                <span className="user-name">
                                                    {activeUser && activeUser.user.first_name} {activeUser && activeUser.user.last_name}
                                                </span>
                                    <span className="user-country">
                                                    {activeUser && activeUser.user.country}
                                                </span>
                                </h4>
                                <p className="user-more">
                                    View Profile
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default UserInfo;
