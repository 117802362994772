import React, {Fragment} from 'react'

import UserNoteCollapse from "../components/UserNoteCollapse";
import connect from "react-redux/es/connect/connect";
import {userActions} from "../actions";
import ProfileModal from "./ProfileModal";
import {Modal} from "react-bootstrap";


// const Video = React.forwardRef((props, ref) => <video ref={ref}/>);

class VotingBlock extends React.Component {
    videoRefs = [];

    constructor(props) {
        super(props)
        this.state = {
            collapse: false,
            voting: [],
            no_length: 0,
            disable_no: false,
            yes_length: 0,
            isFavourite: false,
            filter: [],
            playBtn: [],
            currentPlayList: [],
            disableSubmitBtn: true,
            userId: 0,
            openModal: false,
            votingForRound3: [],
            votingForSpecialPrice: [],
            disable_yes_maybe: false,
            prizeVoting: false,
            selectMenu: '',
            closeModal: true
            // activeState: this.props.round && ((this.props.round.schedules.find(x => x.state === 'now_playing') && this.props.round.schedules.find(x => x.state === 'now_playing').event) || (this.props.round.schedules.find(x => x.state === 'waiting') && this.props.round.schedules.find(x => x.state === 'waiting').event))
        }
        // this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (e, round_id, user_id, jury_id, vote_label, vote_value) => {
        const rule = parseInt(this.props.data.vote_rules.find(x => x.vote_label === 'no').rules.rule)
        const id = user_id;
        const {voting} = this.state;
        const data = this.props.data;
        const no = rule;
        let no_choosen;
        let yes_maybe_choosen;
        const vote = {
            round_id: round_id,
            user_id: user_id,
            jury_id: jury_id,
            vote_label: vote_label,
            vote_value: vote_value
        };
        let votes;
        if (e.target.checked) {
            if (voting.some(vote => vote.user_id === id)) {
                votes = [...voting.filter(votes => votes.user_id !== id), vote]
            } else {
                votes = [...voting, vote]
            }
            this.setState({
                voting: votes
            }, () => {
                no_choosen = this.state.voting.filter(x => x.vote_label === 'no');
                yes_maybe_choosen = this.state.voting.filter(x => x.vote_label === 'yes' || x.vote_label === 'maybe');
                this.setState({
                    no_length: no_choosen.length,
                    yes_length: yes_maybe_choosen.length

                })
                if (this.state.voting.length === this.props.data.users.length) {
                    this.setState({
                        disableSubmitBtn: false
                    })
                } else {
                    this.setState({
                        disableSubmitBtn: true
                    })
                }
            })
        } else {
            const delElIndex = voting.findIndex(el => el.user_id === user_id)
            const newVotes = [
                ...voting.slice(0, delElIndex),
                ...voting.slice(delElIndex + 1)
            ]
            this.setState({
                voting: newVotes
            }, () => {
                no_choosen = this.state.voting.filter(x => x.vote_label === 'no');
                yes_maybe_choosen = this.state.voting.filter(x => x.vote_label === 'yes' || x.vote_label === 'maybe');
                this.setState({
                    no_length: no_choosen.length,
                    yes_length: yes_maybe_choosen.length

                })
                if (this.state.voting.length === this.props.data.users.length) {
                    this.setState({
                        disableSubmitBtn: false
                    })
                } else {
                    this.setState({
                        disableSubmitBtn: true
                    })
                }
            })
        }


    };

    handleChangeRound3Voting = (e, round_id, user_id, jury_id, vote_label, vote_value) => {
        const id = user_id;
        const {votingForRound3} = this.state;
        const vote = {
            round_id: round_id,
            user_id: user_id,
            jury_id: jury_id,
            vote_label: vote_label,
            vote_value: vote_value
        };
        let votes;
        if (e.target.checked) {
            if (votingForRound3.some(vote => vote.user_id === id)) {
                votes = [...votingForRound3.filter(votes => votes.user_id !== id), vote]
            } else {
                votes = [...votingForRound3, vote]
            }
            this.setState({
                votingForRound3: votes
            }, () => {
                // console.log(this.state.votingForRound3.length)
            })
        } else {
            const delElIndex = votingForRound3.findIndex(el => el.user_id === user_id)
            const newVotes = [
                ...votingForRound3.slice(0, delElIndex),
                ...votingForRound3.slice(delElIndex + 1)
            ]
            this.setState({
                votingForRound3: newVotes
            }, () => {
                // console.log(this.state.votingForRound3.length)
            })
        }
    }

    handleChangeSpecialPrice = (e, user_id, jury_id, idx, name) => {
        this.setState({
            selectMenu: ''
        })

        const id = user_id;
        const {votingForSpecialPrice} = this.state;
        const {budges} = this.props

        const vote = {
            competition_id: 1,
            user_id: user_id,
            jury_id: jury_id,
            prize_id: idx
        };
        let votes;
        let tags;
        let target = e.target.parentNode.parentNode.parentNode.parentNode.childNodes[0].childNodes[0]
        if (e.target.checked) {


            // e.target.parentNode.parentNode.parentNode.parentNode.childNodes[0].childNodes[0].innerHTML += ''

            votes = [...votingForSpecialPrice, vote]
            this.setState({
                votingForSpecialPrice: votes
            }, () => {
                tags = this.state.votingForSpecialPrice.filter(i => i.user_id === user_id).map(el => {
                    return el.prize_id
                })
                target.textContent = tags.map(it => {
                    if (tags.length) {
                        return budges.budges.find(tags => tags.id === it).name
                    }
                })


            })
        } else {
            // e.target.parentNode.parentNode.parentNode.parentNode.childNodes[0].childNodes[0].textContent = 'Select the Nomination'.toUpperCase()
            const delElIndex = votingForSpecialPrice.findIndex(el => el.user_id === user_id && el.prize_id === idx)
            console.log(delElIndex)
            const newVotes = [
                ...votingForSpecialPrice.slice(0, delElIndex),
                ...votingForSpecialPrice.slice(delElIndex + 1)
            ]
            this.setState({
                votingForSpecialPrice: newVotes
            }, () => {
                tags = this.state.votingForSpecialPrice.filter(i => i.user_id === user_id).map(el => {
                    return el.prize_id
                })
                target.textContent = tags.map(it => {
                    if (tags.length) {
                        return budges.budges.find(tags => tags.id === it).name
                    }
                })
                if (tags.length === 0) {
                    target.textContent = 'Select the Nomination'.toUpperCase()
                }
            })
        }
    }


    nextVoting = () => {
        this.props.getUsersForSpecialPrice()
        this.setState({
            prizeVoting: true
        })
    }
    openSelectMenu = (e, id) => {
        const {selectMenu} = this.state;
        this.setState({
            selectMenu: selectMenu === id ? '' : id
        })
    }
    handleChangeFilter = (e, id) => {
        const {checked, value} = e.target;
        if (checked) {
            if (id === 7) {
                this.setState({
                    isFavourite: true
                })
            } else {
                this.setState(prevState => ({
                    filter: [...prevState.filter, value]
                }))
            }
        } else if (id === 7) {
            this.setState({
                isFavourite: false
            })
        } else if (id !== 7) {
            let array = [...this.state.filter]; // make a separate copy of the array
            let index = array.indexOf(value)
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({filter: array});
            }
        }
    }
    setRef = (ref) => {
        this.videoRefs.push(ref);
    };
    playVideo = (e, url, id, idx, index) => {
        const {currentPlayList} = this.state;
        this.videoRefs.map((item, i) => {
            item.pause();
        })
        this.videoRefs[id].src = url;
        this.videoRefs[id].play();
        const playList = {
            id: index,
            user: idx
        }
        let play;
        if (currentPlayList.some(playList => playList.user === idx)) {
            play = [...currentPlayList.filter(play => play.user !== idx), playList]
        } else {
            play = [...currentPlayList, playList]
        }

        this.setState(prevState => ({
            currentPlayList: play,
            playBtn: [...prevState.playBtn, id]
        }))
    }
    playFirstVideo = (e, id, url, user) => {
        const playList = {
            id: 0,
            user: user
        }
        let play;
        const {currentPlayList} = this.state;
        play = [...currentPlayList, playList]
        this.videoRefs.map((item, i) => {
            item.pause();
        })
        this.videoRefs[id].src = url;
        this.videoRefs[id].play();
        this.setState(prevState => ({
            currentPlayList: play,
            playBtn: [...prevState.playBtn, id]
        }))
    }

    openModal = (e, id) => {
        this.setState({
            openModal: !this.state.openModal,
            userId: id
        })
    }

    closeModal = () => {
        this.setState({
            closeModal: !this.state.closeModal
        })
    }
    participantBlockStyle = (url) => {
        return {
            backgroundSize: 'contain',
            backgroundPosition: 'center top',
            paddingTop: '60%',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#04274C',
            backgroundImage: `url(${url})`
        }
    }

    render() {
        const {round_number, budges, user, data, votes, users} = this.props;
        console.log(votes)
        const {
            no_length,
            disable_no,
            isFavourite,
            yes_length,
            closeModal,
            filter,
            playBtn,
            currentPlayList,
            openModal,
            disableSubmitBtn,
            votingForRound3,
            votingForSpecialPrice,
            userId,
            voting,
            selectMenu,
            prizeVoting
        } = this.state;
        const noDefaultCount = data && parseInt(data.vote_rules.find(x => x.vote_label === 'no').rules.rule);
        const yeMaybeDefaultCount = data && parseInt(data.vote_rules.find(x => x.vote_label === 'yes').rules.rule);
        // let myRules = this.props.data.vote_rules.find(x => x.vote_label === 'no') && this.props.data.vote_rules.find(x => x.vote_label === 'no').rules.rule
        let no_lengthForDiable = this.state.voting.filter(x => x.vote_label === 'no').length;
        let yesMaybe_lengthForDiable = this.state.voting.filter(x => x.vote_label === 'maybe' || x.vote_label === 'yes').length
        let disabledNo = no_lengthForDiable === noDefaultCount;
        let disabledYesMaybe = yesMaybe_lengthForDiable === yeMaybeDefaultCount;
        let votingForRound3Length = votingForRound3.length;
        const openvotingForRound3SubmitBtn = ((votingForRound3Length === (data && data.users.length - 1)) && !votingForRound3.some(x => x.vote_value === 5)) || (votingForRound3Length === (data && data.users.length))
        const openvotingForSpecialPrizeBtn = votingForSpecialPrice.length === 6
        const titleStyle = {
            textAlign: 'center',
            width:'100vw',
            height:'80vh',
            alignItems:'center',
            display:'flex',
            justifyContent:'center'
        }
        return (
            <div>
                {data && (
                    data.jury_voted ? (
                        <h1 style={titleStyle}>THANK YOU FOR YOUR VOTING</h1>
                    ) : (
                        <Fragment>
                            <ProfileModal user={data.users[userId]} show={openModal}
                                          closePopup={(e) => this.openModal(e)}/>
                            <div className="container-fluid voting-header">
                                <div className="container voting-info">
                                    <div className="row align-items-center">
                                        <div className="col-md-7">

                                        </div>
                                        <div className="col-md-5">
                                            <div className="row align-items-center justify-content-end">
                                                <div className="col-md-3 text-right">
                                                    <h6>
                                                        TOTAL
                                                        <br/>
                                                        SELECTED
                                                    </h6>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="yesNo-count">
                                                        <div className="count-block green">
                                                            {yes_length}
                                                        </div>
                                                        <div className="count-block-text">
                                                            YES /
                                                            <br/>
                                                            MAYBE
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 text-right">
                                                    <div className="yesNo-count">
                                                        <div className="count-block red">
                                                            {no_length}
                                                        </div>
                                                        <div className="count-block-text">
                                                            NO
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <button className="submit-voting" disabled={disableSubmitBtn}
                                                            onClick={() => this.props.handleSubmit({votes: voting})}
                                                            data-text={`Please make sure you select exactly 5 YES/MAYBE and 5 NO options`}>
                                                        {(votes && votes.loading) ? (
                                                            <img
                                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                                                        ) : (<span>SUBMIT</span>)}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <form action="" className="row">
                                            {data && data.users.map((item, id) => {
                                                return (
                                                    <div className="col-md-4" key={id}
                                                         style={{
                                                             order: `${item.notes[0] && (isFavourite && item.notes[0].note.favourite) ? '1' : item.notes[0] && (item.notes[0].note.badges.some(item => filter.includes(item))) ? '2' : (id + 3)}`
                                                         }}>
                                                        <div className="participant-block">
                                                            <div style={this.participantBlockStyle(item.avatar)}/>
                                                            <div style={{display: 'none'}}
                                                                 className="participant-video">
                                                                <div className="user-videos-list">
                                                                    <ul>
                                                                        {item.performances.map((el, index) => {
                                                                            return (
                                                                                <li key={index}
                                                                                    onClick={e => this.playVideo(e, el.url, id, item.id, index)}
                                                                                    className={`${currentPlayList && (currentPlayList.some(x => x.id === index && x.user === item.id)) && 'active'}`}>
                                                                                    {currentPlayList && (currentPlayList.some(x => x.id === index && x.user === item.id)) ? (
                                                                                        <span
                                                                                            className="active-tag"/>
                                                                                    ) : (
                                                                                        <span></span>
                                                                                    )}
                                                                                    <span>{`Video ${index + 1}`}</span>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                                <div
                                                                    className={` video-play ${playBtn.some(x => x === id) && 'hide'}`}
                                                                    onClick={e => this.playFirstVideo(e, id, item.performances[0].url, item.id)}/>
                                                                <video controls
                                                                       src={item.performances[0] && item.performances[0].url}
                                                                       ref={this.setRef}/>
                                                            </div>
                                                            <div className="participant-info">
                                                                <div className="w-100 d-flex align-items-center">
                                                                    <div className="avatar">
                                                                        <img src={item.avatar} alt=""/></div>
                                                                    <div
                                                                        className="d-inline-block participant-prof">
                                                                        <div className="participant-name">
                                                                            {item.first_name} {item.last_name}
                                                                        </div>
                                                                        <div className="participant-country">
                                                                            {item.country}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="participant-more"
                                                                     onClick={(e) => this.openModal(e, id)}>
                                                                    <span className="participant-more-btn"/>
                                                                </div>
                                                            </div>
                                                            <div className="participant-voting row">
                                                                {data && data.vote_rules.map((itm, i) => {
                                                                    return (
                                                                        <div className="col-md-4" key={i}>
                                                                            <label
                                                                                className={disabledNo && (itm.vote_label === 'no' && !voting.some(x => x.user_id === item.id && x.vote_value === itm.vote_value)) ? 'voting-checkbox disabled' : (((itm.vote_label === 'yes' || itm.vote_label === 'maybe') && disabledYesMaybe) && (!voting.some(x => x.user_id === item.id && x.vote_value === itm.vote_value))) ? 'voting-checkbox disabled' : 'voting-checkbox'}>
                                                                                <input type="checkbox"
                                                                                       name={`${item.id}_yes_no_maybe`}
                                                                                       value={itm.vote_value}
                                                                                       onChange={e => this.handleChange(e, data.id, item.id, user.id, itm.vote_label, itm.vote_value)}
                                                                                       checked={voting && (voting.some(x => x.user_id === item.id && x.vote_value === itm.vote_value))}
                                                                                       disabled={((itm.vote_label === 'no' && disabledNo) && (!voting.some(x => x.user_id === item.id && x.vote_value === itm.vote_value))) || (((itm.vote_label === 'yes' || itm.vote_label === 'maybe') && disabledYesMaybe) && (!voting.some(x => x.user_id === item.id && x.vote_value === itm.vote_value)))}
                                                                                />
                                                                                <span
                                                                                    className={itm.vote_label === 'no' ? 'checkmark red' : itm.vote_label === 'yes' ? 'checkmark green' : itm.vote_label === 'maybe' ? 'checkmark blue' : 'checkmark'}>
                                                                <span className="checkmark-txt">
                                                                    {itm.vote_label}
                                                                </span>
                                                            </span>
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                )}
            </div>
        )
    }
}

function mapState(state) {
    return {
        user: state.authentication.user,
        votes: state.votes,
        round: state.currentRound.round,
        users: state.allUsersForVoting
    }
}

const actionCreators = {
    handleSubmit: userActions.submitVoting,
    getUsersForSpecialPrice: userActions.getUsersForSpecialPrice
};
export default connect(mapState, actionCreators)(VotingBlock);
