import React, {Component, Fragment} from 'react';
import {Switch, Router, Route} from 'react-router-dom';
import {PrivateRoute} from './components';
import HeaderComponent from "./components/Header";
import Home from "./views/Home";
import ForgotPassword from "./views/ForgotPassword";
import Login from "./views/Login";
import {connect} from 'react-redux';
import {userActions} from './actions';
import {history} from './helpers';
import ResetPassword from "./views/ResetPassword";

import Pusher from 'pusher-js';
import LiveVideoPlayer from "./components/LiveVideoPlayer";


class App extends React.PureComponent {
    clockRef = null;

    constructor(props) {
        super(props);
        this.state = {
            votingTime: Date.now(),
            startVoting: false,
            activeState: '',
            timer: null,
            round: props.round
        }
        this.closeModal = this.closeModal.bind(this);
        this.setClockRef = this.setClockRef.bind(this);
    }

    closeModal() {
        this.setState({
            startVoting: true
        })
        this.clockRef.start();
    }

    setClockRef(ref) {
        this.clockRef = ref;
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.votes !== this.props.votes) {
    //         if (!this.props.votes.loading) {
    //             this.props.getDate();
    //             const {date, round} = this.props;
    //             console.log(date)
    //             let timerForResult = round && (((new Date(round.schedules.find(x => x.event === 'voting_result').start_date).getTime() - new Date(date && date.date.server_date).getTime())))
    //             this.setState({
    //                 // activeState: 'voting_result',
    //                 timer: timerForResult
    //             }, () => {
    //                 window.location.reload()
    //             })
    //         }
    //     }
    //     if (prevProps.revoting !== this.props.revoting) {
    //         if (!this.props.revoting.loading) {
    //             this.props.getDate();
    //             const {date, round} = this.props;
    //             let timerForResult = round && (((new Date(round.schedules.find(x => x.event === 'voting_result').date).getTime() - new Date(date && date.date.server_date).getTime())))
    //             this.setState({
    //                 // activeState: 'voting_result',
    //                 timer: timerForResult
    //             }, () => {
    //                 window.location.reload()
    //             })
    //         }
    //     }
    //     if (prevProps.user !== this.props.user) {
    //         if (!this.props.loggingIn.loggedIn && localStorage.getItem('user')) {
    //             this.props.getDate();
    //             this.props.getCurrentRound();
    //             this.props.getBudges();
    //             const {round} = this.props
    //             let ifRoundOrVoting = round && (round.schedules.find(x => x.state === 'now_playing') || round.schedules.find(x => x.state === 'waiting'));
    //             this.setState({
    //                 activeState: ifRoundOrVoting.event
    //             })
    //         }
    //     }
    //     // if (prevProps.round !== this.props.round) {
    //     //     if (!this.props.roundIsLoaded) {
    //     //         const {round} = this.props;
    //     //         let ifRoundOrVoting = round && (round.schedules.find(x => x.state === 'now_playing') || round.schedules.find(x => x.state === 'waiting'));
    //     //         this.setState({
    //     //             activeState: ifRoundOrVoting.event,
    //     //             round: round
    //     //         })
    //     //     }
    //     // }
    // }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            this.props.getCurrentRound().then(res => {
                if (res.type === 'GET_CURRENTROUND_SUCCESS') {
                    this.props.getDate();
                    const {round, date} = this.props
                    this.props.getRoundVoting()
                    let timerForResult = round && round.schedules.find(x => x.event === 'voting_result') &&  (((new Date(round.schedules.find(x => x.event === 'voting_result').start_date).getTime() - new Date(date && date.date && date.date.server_date).getTime())))
                    this.setState({
                        // activeState: 'voting_result',
                        timer: timerForResult
                    })
                }
            });
            this.props.getBudges();
            const {user} = this.props
            let pusher = new Pusher('1d9de322f411b8a9b382', {
                cluster: 'ap2'
            });
            let channel = pusher.subscribe('khachaturian');
            channel.bind('refresh_page', function (data) {
                if (data.id === user.user.id || !data.id) {
                    window.location.reload()
                }
            });
            // setTimeout(() => {
            //     let noteForm = document.getElementById("note-form-hide") !== null && document.getElementById("note-form-hide")
            //     noteForm.style.opacity = '0';
            //     noteForm.style.visibility = 'hidden';
            // },20000)
        }
    }

    // handleSubmit = (event, data) => {
    //     console.log(event)
    //     console.log(data)
    //     event.preventDefault();
    //     this.props.submitVoting(data);
    // }
    handleSubmitRevoting = (event, data) => {
        event.preventDefault();
        this.props.submitRevoting(data).then(res => {
            if (res.type === "ADD_REVOTING_SUCCESS") {
                window.location.reload()
            }
        });
    }

    render() {
        const {startVoting, activeState, timer} = this.state;
        const {date, round, voting, budges, revote} = this.props;
        let ifRoundOrVoting = round && (round.schedules.find(x => x.state === 'now_playing') || round.schedules.find(x => x.state === 'waiting'));
        // let votingTime = (ifRoundOrVoting && date.date) && (((ifRoundOrVoting.duration * 1000) - (new Date(date.date && date.date.server_date).getTime() - new Date(ifRoundOrVoting.start_date).getTime())))
        let timerForResult = (round && date.date) && (round.schedules.find(x => x.event === 'voting_result') && new Date(round.schedules.find(x => x.event === 'voting_result').date).getTime() - new Date(date.date.server_date).getTime());
        return (
            <Fragment>
                <HeaderComponent refCallback={this.setClockRef}
                                 // votingTime={votingTime && Date.now() + (votingTime)}
                                 activeState={(activeState === '' && ifRoundOrVoting) ? ifRoundOrVoting.event : activeState}
                                 round={round} voting={voting} revote={revote}/>
                <main>
                    {/*{ifRoundOrVoting && ifRoundOrVoting.event === 'performance' && (*/}
                    {/*<LiveVideoPlayer/>*/}
                    {/*)}*/}
                    <Router history={history}>
                        <Switch>
                            <PrivateRoute exact path="/"

                                          date={date}
                                          component={Home}
                                          startVoting={!startVoting}
                                          closeModal={this.closeModal}
                                          newSchedules={round && round.schedules}
                                          activeState={(activeState === '' && ifRoundOrVoting) ? ifRoundOrVoting.event : activeState}
                                          round={round && round}
                                // data={voting && voting}
                                //           handleSubmit={this.handleSubmit}
                                          handleSubmitRevoting={this.handleSubmitRevoting}
                                          timer={timer === null ? timerForResult : timer}
                                          budges={budges}
                                          voting={voting}
                                          {...this.props}/>
                            <Route path="/login" component={Login}/>
                            <Route path="/forgot-password" component={ForgotPassword}/>
                            <Route path="/reset-password" component={ResetPassword}/>
                        </Switch>
                    </Router>
                </main>
            </Fragment>
        );
    }
}

function mapState(state) {
    return {
        date: state.date,
        round: state.currentRound.round,
        roundIsLoaded: state.currentRound.loading,
        votes: state.votes,
        budges: state.budges,
        user: state.authentication,
        loggingIn: state.authentication,
        voting: state.currentVoting.voting,
        revote: state.current_revoting.revote_users
    };
}

const actionCreators = {
    getDate: userActions.getServerDate,
    getCurrentRound: userActions.getCurrentRound,
    submitVoting: userActions.submitVoting,
    submitRevoting: userActions.submitRevoting,
    getBudges: userActions.getBudges,
    getRoundVoting: userActions.getRoundVoting
};
export default connect(mapState, actionCreators)(App)

